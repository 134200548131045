import i18n from '../i18n';
import { BUSINESS_HQ_CODE } from '../constants/businessHQ';
import { formatMoneyAmount, getCurrencyRepresentationConfigByHQ } from './currency';

const { BR, MX, CO, PE, CL } = BUSINESS_HQ_CODE;
let salesRangesOptions = {};

class MonthlySalesData {
  constructor(currency, usdRate, amounts, businessHQCode) {
    this.currency = currency;
    this.usdRate = usdRate;
    this.amounts = amounts;
    this.businessHQCode = businessHQCode;
  }

  get convertedAmounts() {
    return this.amounts.map(
      amount => `${formatMoneyAmount((amount / this.usdRate).toFixed(2))} USD`
    );
  }

  get displayedAmounts() {
    return this.amounts.map(
      amount =>
        `${formatMoneyAmount(amount, getCurrencyRepresentationConfigByHQ(this.businessHQCode))} ${
          this.currency
        }`
    );
  }
}
new MonthlySalesData('MXN', 20, [1_000_000, 7_000_000, 10_000_000], MX);
new MonthlySalesData('COP', 4000, [200_000_000, 1_500_000_000, 2_000_000_000], CO);
new MonthlySalesData('CLP', 850, [45_000_000, 315_000_000, 450_000_000], CL);
new MonthlySalesData('PEN', 4, [185_000, 1_300_000, 1_800_000], PE);
new MonthlySalesData('R$', 5, [250_000, 1_800_000, 2_500_000], BR);
new MonthlySalesData('USD', 1, [50_000, 350_000, 500_000]);
const formatMonthlySalesOption = ({ value, t }) => {
  return {
    value,
    label: t ? `${i18n.t(t)} ${value}` : value,
    amplitudeValue: t
      ? `${i18n.t(t, {
          lng: 'en'
        })} ${value}`
      : value
  };
};

export const getMonthlySalesOptions = (businessHQ, options = salesRangesOptions) => {
  salesRangesOptions = options;

  return options.map((option, index) => {
    let formattedOpt = {};

    if (index === 0 && option?.minValue === 0) {
      formattedOpt = formatMonthlySalesOption({
        value: `${option?.maxValue?.toLocaleString()} ${option?.currencyCode}`,
        t: 'completeRegistration:description.lower'
      });
    } else if (option?.maxValue === 0) {
      formattedOpt = formatMonthlySalesOption({
        value: `${option?.minValue?.toLocaleString()} ${option?.currencyCode}`,
        t: 'completeRegistration:description.higher'
      });
    } else {
      formattedOpt = formatMonthlySalesOption({
        value: `${option?.minValue?.toLocaleString()} ${
          option?.currencyCode
        } - ${option?.maxValue?.toLocaleString()} ${option?.currencyCode}`
      });
    }

    return {
      ...formattedOpt,
      ...option
    };
  });
};

export const isHardRejectedMonthlySales = (businessHQ, monthlySales) => {
  const options = getMonthlySalesOptions(businessHQ);
  return monthlySales === options[0]?.value;
};

export const isSoftRejectedMonthlySales = (businessHQ, monthlySales) => {
  const options = getMonthlySalesOptions(businessHQ);
  return false; //monthlySales === options[1]?.value;
};
