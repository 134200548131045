import React, { ReactElement, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { useAmplitudeInitialization } from '../utils/amplitude';
import { useClickWrapSnippet } from '../hooks/useClickWrapSnippet';
import {
  BUSINESS_PROFILE,
  JOIN,
  REJECTION,
  THANK_YOU,
  WAITLIST,
  SAT_CONNECTION
} from '../constants/routes.constants';

import ProtectedRoute from './ProtectedRoute';
import CreateAccount from '../pages/CreateAccount';
import Rejected from '../pages/Rejected';
import ThankYou from '../pages/ThankYou';
import Waitlist from '../pages/Waitlist';
import SatConnection from '../pages/SatConnection';
const BusinessProfile = lazy(() => import('../pages/BusinessProfile'));

interface IRoute {
  path: string;
  name: string;
  redirect?: string;
  condition?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  component: ReactElement<any, any> | null;
}

const PublicRoute = () => {
  const routes: IRoute[] = [
    {
      path: JOIN,
      component: <CreateAccount />,
      name: 'join'
    },
    {
      path: THANK_YOU,
      component: <ThankYou />,
      name: 'thankYou'
    },
    {
      path: REJECTION,
      component: <Rejected revenueIssue />,
      name: 'rejection'
    },
    {
      path: WAITLIST,
      component: <Waitlist />,
      name: 'waitlist'
    },
    {
      path: SAT_CONNECTION,
      component: <SatConnection />,
      name: 'satConnection'
    },
    {
      path: BUSINESS_PROFILE,
      component: <BusinessProfile />,
      name: 'businessProfile'
    }
  ];

  useClickWrapSnippet();

  // Init amplitude in project
  useAmplitudeInitialization();

  return (
    <Routes>
      {routes.map(route => (
        <Route
          key={route.path}
          path={route.path}
          element={
            <ProtectedRoute
              key={`protected-${route.path}`}
              name={route.name}
              {...(route.condition && { condition: route.condition })}
              {...(route.redirect && { redirect: route.redirect })}
            >
              {route.component}
            </ProtectedRoute>
          }
        />
      ))}
      <Route path="*" element={<Navigate to={JOIN} replace />} />
    </Routes>
  );
};

export default PublicRoute;
