import { selectAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  selectAnatomy.keys
);

const outline = definePartsStyle({
  field: {
    height: '2.5rem',
    fontFamily: 'PublicSansRegular',
    fontWeight: 400,
    fontSize: '1rem',
    lineHeight: '20px',
    borderRadius: 8,
    borderColor: 'dark.300',
    borderWidth: 2,
    background: 'neutral.50',

    _focusWithin: {
      dropShadow: 'rgba(48,149,151,0.28)',
      borderColor: 'teal.400'
    },
    _focusVisible: {
      borderColor: 'primary.400',
      boxShadow: 'rgba(48,149,151,0.28)'
    },
    _hover: {
      borderColor: 'primary.400',
      dropShadow: 'rgba(48,149,151,0.28)',
      ringOffset: '1px',
      ring: '.5px'
    }
  },
  icon: {
    color: 'neutral.600'
  }
});

export default defineMultiStyleConfig({
  variants: { outline }
});
