import { extendTheme, space } from '@chakra-ui/react';

import Button from './components/button';
import Input from './components/input';
import Form from './components/form';
import FormLabel from './components/formLabel';
import Heading from './components/heading';
import Text from './components/text';
import { sizes } from './foundations/sizes';
import { breakpoints } from './foundations/breakpoints';
import { colors } from './foundations/colors';
import { fonts, fontSizes, lineHeights } from './foundations/fonts';
import { radii } from './foundations/border';
import Checkbox from './components/Checkbox';
import Tag from './components/Tag';
import Tooltip from './components/Tooltip';
import Modal from './components/Modal';
import Select from './components/Select';
import { shadows } from './foundations/shadows';

// Add your color mode config
const config = {
  initialColorMode: 'light',
  useSystemColorMode: false
};

export default extendTheme({
  components: {
    Button,
    Input,
    Form,
    FormLabel,
    Text,
    Heading,
    Checkbox,
    Tag,
    Tooltip,
    Modal,
    Select
  },
  fonts,
  lineHeights,
  fontSizes,
  radii,
  space,
  sizes,
  breakpoints,
  shadows,
  colors,
  config
});
