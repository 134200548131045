import { Button, Flex, Menu, MenuButton, MenuItem, MenuList, Icon } from '@chakra-ui/react';
import { Check, ChevronDown, Globe } from '@emotion-icons/feather';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadState, saveState } from '../../../utils/localStorage';
import { Item } from './LanguageSwitcher.styled';
import { useLocation } from 'react-router-dom';

const options = [
  { value: 'en', label: 'English', selectedOption: 'EN' },
  { value: 'es', label: 'Español', selectedOption: 'ES' }
  // PT option temp hidden
  // { value: 'pt', label: 'Português', selectedOption: 'PT' }
];

const LanguageSwitcher = () => {
  const { search } = useLocation();
  const { i18n } = useTranslation();
  const language = useMemo(() => {
    // nosemgreps
    return search.includes('es')
      ? 'es'
      : search.includes('en')
      ? 'en'
      : loadState('tribalLng') ?? i18n.languages[0];
  }, [i18n]);

  const [selected, setSelected] = useState(
    options.find(option => option.value === language.toLowerCase()) ??
      // nosemgreps
      options[0]
  );

  const handleChangeLanguage =
    (option: { value: string; label: string; selectedOption: string }) => () => {
      setSelected(option);
      i18n.changeLanguage(option.value);
      saveState('tribalLng', option.value);
    };

  useEffect(() => {
    i18n.changeLanguage(selected.value);
  }, [selected]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Flex ml={{ xs: '0.3rem', md: '1.8rem' }} h={'44px'} justifyContent={'center'}>
      <Menu>
        <MenuButton
          as={Button}
          bg="transparent"
          color="neutral.900"
          sx={{
            _active: {},
            _hover: { background: 'neutral.200' },
            fontSize: '14px',
            padding: '7px',
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '8px'
          }}
          rightIcon={<Icon as={ChevronDown} />}
        >
          <Item>
            <Icon as={Globe} strokeWidth="0.1rem" color="neutral.900" w="3" h="3" mr="0.6rem" />
            {selected.selectedOption}
          </Item>
        </MenuButton>
        <MenuList
          sx={{
            minWidth: '100px',
            display: 'flex',
            flexDirection: 'column',
            padding: '0.6rem',
            button: {
              mb: 1
            },
            'button:last-child': {
              mb: 0
            },
            color: 'neutral.700'
          }}
        >
          {options.map((option, index) => (
            <MenuItem
              key={`menu-item-language_${index}`}
              onClick={handleChangeLanguage(option)}
              sx={{
                borderRadius: '4px',
                fontSize: '14px',
                minW: '11rem',
                fontWeight: 600,
                display: 'inline-block',
                padding: ' 4px 8px',
                _focus: {
                  background: 'trasparent'
                },
                _hover: {
                  background: 'neutral.300',
                  color: 'inherit'
                },
                background: `${selected.value === option.value ? 'primary.600' : ''}`,
                color: `${selected.value === option.value ? 'white' : ''}`
              }}
            >
              <Flex justify="space-between" align="center" w="100%">
                {option.label}
                {selected.value === option.value && (
                  <Icon as={Check} strokeWidth="0.2rem" width={5} height={5} color="white" />
                )}
              </Flex>
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default LanguageSwitcher;
