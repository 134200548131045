import {
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  FormErrorMessage,
  InputRightElement,
  useMediaQuery,
  Input as ChakraInput,
  InputGroup,
  Text,
  Button,
  Tooltip
} from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';
import { AlertTriangle } from '@emotion-icons/feather';

import { t } from 'i18next';
import { ReactComponent as Eye } from '../../assets/images/eye.svg';
import { ReactComponent as EyeOff } from '../../assets/images/eye-off.svg';
import { ReactComponent as Info } from '../../assets/images/info-tooltip.svg';
import { InputComponent } from '../../types/Interface';
import React, { useEffect } from 'react';

const Input = ({
  label,
  helper,
  stateName,
  type = 'text',
  showError = true,
  width = '100%',
  value,
  isInvalid,
  validation,
  show,
  setShow,
  mt = '1.5rem',
  maxLength = 256,
  isRequired,
  toolTipTxt,
  isDisabled,
  ...props
}: InputComponent) => {
  const [isLessThan740] = useMediaQuery('(max-width: 740px)');
  const { register } = useFormContext(); // retrieve all hook methods

  const onChange = () => setShow(!show);

  useEffect(() => {
    document
      .querySelectorAll('.numberInput')
      .forEach(elm => elm.addEventListener('wheel', e => e.preventDefault()));
  }, []);

  return (
    <FormControl mt={mt} w={isLessThan740 ? '100%' : width} mb="0" isInvalid={!!isInvalid}>
      {label?.length && (
        <FormLabel fontSize="0.875rem" as="span" display="flex" alignItems="end">
          {label}
          {isRequired && (
            <Text color="red.500" ml="0.2rem" lineHeight={'1rem'}>
              *
            </Text>
          )}
          {toolTipTxt && (
            <Tooltip
              placement="right"
              hasArrow
              label={toolTipTxt}
              bg="#5F636B"
              fontSize="12px"
              py="1.7rem"
            >
              <Info style={{ marginLeft: '0.3rem', cursor: 'pointer' }} />
            </Tooltip>
          )}
        </FormLabel>
      )}

      {helper?.length && (
        <FormHelperText mb=".25rem" mt="0">
          {helper}
        </FormHelperText>
      )}
      <InputGroup>
        <ChakraInput
          className={type === 'number' ? 'numberInput' : undefined}
          isInvalid={!!isInvalid}
          isDisabled={isDisabled}
          errorBorderColor={'red.500'}
          type={type}
          value={value}
          maxLength={maxLength}
          {...props}
          {...register(stateName, validation)}
          sx={{
            _disabled: {
              color: 'neutral.400',
              cursor: 'not-allowed',
              backgroundColor: 'neutral.200'
            }
          }}
        />
        {stateName === 'password' && (
          <InputRightElement>
            <Button
              onClick={onChange}
              sx={{
                padding: '0',
                background: 'none',
                backgroundColor: 'none',
                height: '2.05rem',
                width: '2.05rem',
                _focus: {
                  backgroundColor: 'blue.100',
                  border: '0.063rem solid white',
                  boxShadow: 'inset 0 0 0 1px white, 0 0 3px 1px rgba(48,149,151,0.28)',
                  outline: 'none'
                },
                _hover: {
                  backgroundColor: 'neutral.200',
                  filter: 'contrast(.7)'
                },
                _active: {
                  backgroundColor: 'neutral.300'
                }
              }}
            >
              {!show ? <Eye /> : <EyeOff />}
            </Button>
          </InputRightElement>
        )}
      </InputGroup>

      <FormErrorMessage fontSize=".9rem" textAlign="start">
        {showError ? (
          <>
            <Icon as={AlertTriangle} strokeWidth="0.15rem" fontSize="1rem" mr=".2rem" mb=".3rem" />
            {t(isInvalid?.message)}
          </>
        ) : null}
      </FormErrorMessage>
    </FormControl>
  );
};

export default Input;
