const fieldRequiredValidation = {
  required: { value: true, message: 'errorMsgs:errors.requiredFields' }
};
const textValidation = {
  maxLength: { value: 256, message: 'errorMsgs:errors.maxLength256' },
  ...fieldRequiredValidation
};

const website = {
  pattern: {
    value: /^(?:http(s)?:\/\/)?[\w.]+(?:\.[\w\.]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
    message: 'errorMsgs:errors.website'
  }
};
const companyRFC = {
  pattern: {
    value: /^[A-Za-z]{3}[0-9]{6}[A-Za-z0-9]{3}$/,
    message: 'errorMsgs:errors.companyRFC'
  }
};
export const fieldsValidation = {
  email: {
    required: { value: true, message: 'errorMsgs:errors.emailRequired' },
    pattern: {
      value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      message: 'errorMsgs:errors.invalidEmail'
    },
    validate: (value: string) =>
      /^(?!.*@(?:gmail|googlemail|yahoo|hotmail|outlook|me|icloud|live|uol|protonmail|bol|aol|gnail|gmai|gmal|hotmai|hotmal|msn|google|facebook|twitter|protonmail|outlook|zoho|hotmal)\..*$).*$/.test(
        value
      ) === true || 'errorMsgs:errors.rejectedDomain'
  },

  businessEmail: {
    required: { value: true, message: 'errorMsgs:errors.businessEmailRequired' },
    pattern: {
      value: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
      message: 'errorMsgs:errors.invalidBusinessEmail'
    },
    validate: (value: string) =>
      /^(?!.*@(?:gmail|googlemail|yahoo|hotmail|outlook|me|icloud|live|uol|protonmail|bol|aol|gnail|gmai|gmal|hotmai|hotmal|msn|google|facebook|twitter|protonmail|outlook|zoho|hotmal)\..*$).*$/.test(
        value
      ) === true || 'errorMsgs:errors.rejectedDomain'
  },
  companyName: {
    ...textValidation,
    maxLength: { value: 256, message: 'errorMsgs:errors.maxLength256' },
    pattern: {
      value: /^(?!\s+$)/,
      message: 'errorMsgs:errors.requiredFields'
    }
  },
  firstName: {
    ...textValidation,
    maxLength: { value: 64, message: 'errorMsgs:errors.firstName' },
    pattern: {
      value: /^(?!\s+$)[a-zA-ZÀ-ÖØ-öø-ÿ\s-]+$/,
      message: 'errorMsgs:errors.firstName'
    }
  },
  lastName: {
    ...textValidation,
    pattern: {
      value: /^(?!\s+$)[a-zA-ZÀ-ÖØ-öø-ÿ\s-]+$/,
      message: 'errorMsgs:errors.sureName'
    }
  },
  title: {
    ...textValidation,
    pattern: {
      value: /^(?!\s+$)[a-zA-Z0-9\s]*$/,
      message: 'errorMsgs:errors.title'
    }
  },
  isRegisteredCompany: fieldRequiredValidation,
  monthlySales: fieldRequiredValidation,
  sectors: fieldRequiredValidation,
  industry: fieldRequiredValidation,
  businessHQ: { required: { value: true, message: 'errorMsgs:errors.requiredFields' } },
  phoneNumber: {
    ...fieldRequiredValidation,
    maxLength: { value: 15, message: 'errorMsgs:errors.maxLength15' },
    minLength: { value: 5, message: 'errorMsgs:errors.minLength5' }
  },
  companyLegalName: {
    ...textValidation,
    pattern: {
      value: /^(?!\s+$)/,
      message: 'errorMsgs:errors.requiredFields'
    }
  },
  businessAddressOptional: {
    maxLength: { value: 100, message: 'errorMsgs:errors.maxLength100' },
    pattern: {
      value: /^(?!\s+$)/,
      message: 'errorMsgs:errors.requiredFields'
    }
  },
  businessAddress: {
    ...textValidation,
    maxLength: { value: 100, message: 'errorMsgs:errors.maxLength100' },
    pattern: {
      value: /^(?!\s+$)/,
      message: 'errorMsgs:errors.requiredFields'
    }
  },
  website: { ...fieldRequiredValidation, ...website },
  country: fieldRequiredValidation,
  hasISC: fieldRequiredValidation,
  teamSize: {
    ...fieldRequiredValidation,
    minLength: { value: 1, message: 'errorMsgs:errors.minHeadCount' },
    maxLength: { value: 1000000, message: 'errorMsgs:errors.inValidHeadCount' }
  },
  companyId: {
    ...fieldRequiredValidation,
    pattern: {
      value: /^[a-zA-Z0-9]{5,40}$/,
      message: 'errorMsgs:errors.companyID'
    }
  },
  companyRFC: { ...fieldRequiredValidation, ...companyRFC },
  state: fieldRequiredValidation,
  city: {
    ...fieldRequiredValidation,
    pattern: {
      value: /^(?!\s+$)[a-zA-ZÀ-ÖØ-öø-ÿ\s-]+$/,
      message: 'errorMsgs:errors.invalidCity'
    },
    minLength: { value: 3, message: 'errorMsgs:errors.smallCityName' }
  },
  zipCode: {
    ...fieldRequiredValidation,
    pattern: {
      value: /^[0-9]{5,9}$/,
      message: 'errorMsgs:errors.inValidZipCode'
    }
  },
  referralCode: { maxLength: { value: 64, message: 'createAccount:error.maxLength64' } },
  password: {
    ...fieldRequiredValidation,
    minLength: { value: 8, message: 'errorMsgs:errors.password' }
  }
};
