import { defineStyleConfig, theme } from '@chakra-ui/react';

export default defineStyleConfig({
  baseStyle: {
    ...theme.components.Input.defaultProps,
    field: {
      fontFamily: 'PublicSansRegular'
    }
  },
  defaultProps: {
    variant: 'base'
  },
  variants: {
    base: {
      field: {
        '--padding-with-border': '9px 11px',
        '--padding': '10px 12px',
        height: '40px',
        padding: 'var(--padding)',
        border: '1px solid',
        borderRadius: '8px',
        borderColor: 'neutral.300',
        fontSize: '16px',
        lineHeight: '20px',
        color: 'neutral.700',
        _placeholder: {
          color: 'neutral.500'
        },
        _hover: {
          border: '2px solid',
          borderColor: 'primary.400',
          padding: 'var(--padding-with-border)',
          _disabled: {
            border: '1px solid',
            borderColor: 'neutral.300',
            padding: 'var(--padding)'
          }
        },
        _focus: {
          boxShadow: 'validField'
        },
        _invalid: {
          border: '2px solid',
          borderColor: 'red.500',
          color: 'red.600',
          padding: 'var(--padding-with-border)',

          _focus: {
            boxShadow: 'invalidField'
          }
        },
        _disabled: {
          bg: 'neutral.200',
          color: 'neutral.300'
        }
      }
    }
  }
});
