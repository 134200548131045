import TagManager from 'react-gtm-module';

export const events = {
  accountCreated: 'accountCreated'
};

/**
 * Send custom event into GTM
 * @param {string} event - event name declared during GTM initialization
 * @param {object} data - info that you want to pass to GTM
 */
export const sendEvent = (event, data = {}) => {
  TagManager.dataLayer({
    dataLayer: {
      event,
      ...data
    }
  });
};
