import { useEffect, useState } from 'react';
import TribalAmplitude from 'tribal-systems';
import { useFlags } from 'launchdarkly-react-client-sdk';

export const amplitude = TribalAmplitude.getInstance();

export const useAmplitudeInitialization = () => {
  const { amplitudeEnvironmentSignUp } = useFlags();
  const [isInitialized, setIsInitialized] = useState<boolean>(false);

  useEffect(() => {
    // If the flag is active, it will return the name of the corresponding env
    if (amplitudeEnvironmentSignUp !== 'false' && !isInitialized) {
      TribalAmplitude.getInstance().init(amplitudeEnvironmentSignUp);
      setIsInitialized(true);
    }
  }, [amplitudeEnvironmentSignUp]);
};
