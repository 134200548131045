import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import rootReducer from './root-reducers';
import rootSaga from './root-sagas';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const sagaMiddleware = createSagaMiddleware();
const persistConfig = {
  key: 'root',
  version: 1,
  storage: storage,
  blacklist: ['userData']
};

export const configStore = {
  reducer: persistReducer(persistConfig, rootReducer), //  rootReducer,
  middleware: [sagaMiddleware]
};
const store = configureStore(configStore);

sagaMiddleware.run(rootSaga);

export type RootState = ReturnType<typeof store.getState>;
export default store;

export const persistor = persistStore(store);
