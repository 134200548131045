import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ICreateProfile, IUserData } from '../../types/Interface';

const initialState: IUserData = {
  isLoading: false,
  error: false,
  success: false,
  applicationId: '',
  submitError: false,
  submitSuccess: false,
  participantsId: ''
};

const userDataSlice = createSlice({
  name: 'userData',
  initialState,
  reducers: {
    setProfileCreation: (
      state,
      // eslint-disable-next-line
      _: PayloadAction<{ data: ICreateProfile; withoutUserToken: boolean }>
    ) => {
      state.isLoading = true;
      state.applicationId = '';
      state.success = false;
      state.error = false;
    },
    // eslint-disable-next-line
    setProfileCreationSuccess: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      state.applicationId = id;
      state.isLoading = false;
      state.success = true;
      state.error = false;
    },
    // eslint-disable-next-line
    setProfileCreationFailure: (state, action: PayloadAction<{ error: string }>) => {
      const { error } = action.payload;
      state.applicationId = '';
      state.isLoading = false;
      state.error = error;
      state.success = false;
    },
    setApplicationParticipants: (
      state,
      // eslint-disable-next-line
      _: PayloadAction<{ id: string; data: any; withoutUserToken: boolean }>
    ) => {
      state.isLoading = true;
      state.participantsId = '';
      state.success = false;
      state.error = false;
    },
    // eslint-disable-next-line
    setApplicationParticipantsSuccess: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      state.participantsId = id;
      state.isLoading = false;
      state.success = true;
      state.error = false;
    },
    // eslint-disable-next-line
    setApplicationParticipantsFailure: (state, action: PayloadAction<{ error: string }>) => {
      const { error } = action.payload;
      state.participantsId = '';
      state.isLoading = false;
      state.error = error;
      state.success = false;
    },
    setSubmitApplication: (
      state,
      // eslint-disable-next-line
      _: PayloadAction<{ id: string; withoutUserToken: boolean }>
    ) => {
      state.isLoading = true;
      state.submitSuccess = false;
      state.submitError = false;
    },
    setSubmitApplicationSuccess: state => {
      state.isLoading = false;
      state.submitSuccess = true;
      state.submitError = false;
    },
    setSubmitApplicationFailure: (state, action: PayloadAction<{ error: string }>) => {
      const { error } = action.payload;
      state.isLoading = false;
      state.submitError = error;
      state.submitSuccess = false;
    }
  }
});

export const { actions, reducer } = userDataSlice;
