import { defineStyleConfig, theme } from '@chakra-ui/react';

export default defineStyleConfig({
  baseStyle: {
    ...theme.components.Heading.defaultProps
  },
  defaultProps: {
    variant: 'bold',
    size: 'lg'
  },
  sizes: {
    xxl: {
      fontSize: '56px',
      lineHeight: '78px'
    },
    xl: {
      fontSize: '40px',
      lineHeight: '56px'
    },
    lg: {
      fontSize: '30px',
      lineHeight: '42px'
    },
    md: {
      fontSize: '22px',
      lineHeight: '31px'
    },
    sm: {
      fontSize: '18px',
      lineHeight: '25px'
    }
  },
  variants: {
    bold: {
      fontFamily: 'PublicSansSemiBold'
    },
    regular: {
      fontFamily: 'PublicSansRegular'
    }
  }
});
