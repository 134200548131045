import { defineStyleConfig, theme } from '@chakra-ui/react';

export default defineStyleConfig({
  baseStyle: {
    ...theme.components.Form,
    fontFamily: 'PublicSansSemiBold',
    color: 'neutral.700',
    fontSize: '14px',
    lineHeight: '14px'
  }
});
