import axios from 'axios';
import { API_BASE, API_BASE_URL_PROSPECTS } from '../utils/EV';

export const customHeaders = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  crossDomain: true,
  mode: 'no-cors'
};

const axiosInstance = axios.create({
  baseURL: `${API_BASE}`,
  headers: customHeaders
});

const axiosProspectInstance = axios.create({
  baseURL: `${API_BASE_URL_PROSPECTS}`,
  headers: customHeaders
});

export type IResponse =
  | {
      token: string;
      tokenType: string;
      expiresIn: number;
    }
  | {
      code: string;
      message: string;
    };

const handleError = (error: { response: { status: unknown } }, resetAuth: () => void) => {
  const status = error?.response?.status;
  if (status === 401) resetAuth();
  return error;
};

const headerWithSavedToken = () => {
  return {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    crossDomain: true,
    mode: 'no-cors'
  };
};

export { axiosInstance, axiosProspectInstance, handleError, headerWithSavedToken };
