import { ICreateSatPayload } from '../types/Interface';
import { axiosInstance } from './prospects.instance';

export const getCredentials = (rfc: string) => {
  const url = `/v1/credentials/external-connect/${rfc}`;
  return axiosInstance({
    url,
    method: 'GET'
  });
};

export const createCredential = (data: ICreateSatPayload) => {
  const url = '/v1/credentials/external-connect';
  return axiosInstance({
    url,
    method: 'PUT',
    data
  });
};
