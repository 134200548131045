import axios from 'axios';
import { API_BASE_URL_PROSPECTS } from '../utils/EV';

const API_BASE = API_BASE_URL_PROSPECTS;

const customHeaders = {
  'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  crossDomain: true,
  mode: 'no-cors'
};

const axiosInstance = axios.create({
  baseURL: `${API_BASE}`,
  headers: customHeaders
});

//SET AUTH TOKEN
axiosInstance.interceptors.request.use(config => {
  return config;
});

export { axiosInstance };
