import { useEffect, useState } from 'react';

import { getSectorsAndIndustries } from '../../../services/common.service';
import { log } from '../../../utils';

export const useSectorsAndIndustries = language => {
  const [data, setData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);

  const getData = async () => {
    try {
      setData(await getSectorsAndIndustries(language));
    } catch (e) {
      log(e);
    } finally {
      setIsLoaded(true);
    }
  };

  useEffect(() => {
    getData();
  }, [language]);

  return { isLoaded, data };
};
