const STORAGE_KEY = 'gclid';

const getValue = () => {
  const gclid = JSON.parse(localStorage.getItem(STORAGE_KEY));
  const isGclidValid = gclid && new Date().getTime() < gclid.expiryDate; // valid for 90 days

  return isGclidValid ? gclid.value : null;
};

const clear = () => localStorage.removeItem(STORAGE_KEY);

const getParam = param => {
  const match = RegExp('[?&]' + param + '=([^&]*)').exec(window.location.search); // nosemgrep
  return match && decodeURIComponent(match[1].replace(/\+/g, ' '));
};

const getExpiryRecord = value => {
  const expiryPeriod = 90 * 24 * 60 * 60 * 1000; // 90 day expiry in milliseconds
  const expiryDate = new Date().getTime() + expiryPeriod;

  return { value, expiryDate };
};

const init = () => {
  const gclidParam = getParam('gclid');
  const gclsrcParam = getParam('gclsrc');

  const isGclsrcValid = !gclsrcParam || gclsrcParam.indexOf('aw') !== -1;

  if (gclidParam && isGclsrcValid) {
    localStorage.setItem(STORAGE_KEY, JSON.stringify(getExpiryRecord(gclidParam)));
  }
};

export { getValue, init, clear };
