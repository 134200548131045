import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAccounCreation } from '../../types/Interface';

const initialState = {
  isLoading: false,
  userData: {} as IAccounCreation,
  userDataSuccess: false,
  isExistedEmail: ''
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setUserDataSuccess: (state, action: PayloadAction<{ userData: IAccounCreation }>) => {
      const { userData } = action.payload;
      state.userData = userData;
      state.userDataSuccess = true;
      state.isLoading = true;
    },
    setBusinessEmail: (state, action: PayloadAction<{ businessEmail: string }>) => {
      const { businessEmail } = action.payload;
      state.userData.businessEmail = businessEmail;
    },
    setIsExistedEmail: (state, action: PayloadAction<{ isExistedEmail: string }>) => {
      const { isExistedEmail } = action.payload;
      state.isExistedEmail = isExistedEmail;
    },
    setReset: state => {
      state.isLoading = true;
    },
    setResetSuccess: () => {
      return initialState;
    }
  }
});

export const { actions, reducer } = authSlice;
