import styled from '@emotion/styled';
import { Button as Btn } from '@chakra-ui/react';

export const Button = styled(Btn)`
  background-color: transparent;
  border-radius: 6px;
  height: 29px;
  padding: 0 4px;

  &:hover {
    background: var(--chakra-colors-light-700);
    height: 29px;
  }
  &:focus {
    border: 1px solid var(--chakra-colors-primary-400);
  }
`;

export const Item = styled.div`
  display: flex;
  height: auto;
  justify-content: center;
  align-items: center;
`;
