import { TOP_BUSINESS_HQ_COUNTRIES } from '../constants';
import CountryList from 'country-list-with-dial-code-and-flag';
import i18n from '../../../i18n';
import { isHardRejectedMonthlySales } from '../../../utils/monthlySales';
import * as gclid from '../../../analytics/gclid';

const getBooleanByString = value => value === 'true';

export const isRegisteredCompany = option => getBooleanByString(option.value);
export const prepareDataForBackend = data => {
  return {
    countryHq: data.businessHQ.code,
    companyName: data.companyName,
    isRegisteredCompany: isRegisteredCompany(data.isRegisteredCompany),
    averageMonthlySalesId: data.monthlySales?.id,
    firstName: data.firstName.trim(),
    surname: data.lastName.trim(),
    businessEmail: data.email.trim(),
    areaCode: parseInt(data.phoneCode.code),
    phoneNumber: parseInt(data.phoneNumber),
    chosenLanguage: data.languageId.toUpperCase(),
    sector: data.sectors.value,
    industry: data.industry?.value ?? null,
    utm: data.utm || null,
    referralCode: data.referralCode.trim() || null,
    gclid: gclid.getValue(),
    originChannel: data.originChannel
  };
};

const transformCountry = country => ({
  code: country.dial_code,
  label: country.flag + ' ' + country.dial_code,
  value: `${country.dial_code} ${country.name}`,
  countryCode: country.code
});

const getCountryByCode = countryCode =>
  CountryList.find(el => el.code.toUpperCase() === countryCode.toUpperCase());

export const getDefaultCountryCode = countryCode => {
  const defaultCountry = i18n.language === 'es' ? 'MX' : 'US';
  const country = getCountryByCode(countryCode || defaultCountry);

  return transformCountry(country || getCountryByCode(defaultCountry));
};

export const getPhoneSelectOptions = () => {
  return [
    {
      label: null,
      options: CountryList.map(transformCountry).filter(el =>
        TOP_BUSINESS_HQ_COUNTRIES.includes(el.countryCode)
      )
    },
    { label: null, options: CountryList.map(transformCountry) }
  ];
};

export const getFilteredPhoneCodes = filter =>
  getPhoneSelectOptions().map(({ options, ...data }) => ({
    ...data,
    options: options.filter(({ value }) => value.toLowerCase().includes(filter))
  }));

export const isAccountRejected = accountData =>
  !isRegisteredCompany(accountData.isRegisteredCompany) ||
  isHardRejectedMonthlySales(accountData.businessHQ?.code, accountData.monthlySales?.value);

export const isLetter = event => /^[a-z]$/i.test(event.key);

export const isNumber = event => /^[0-9]$/i.test(event.key);

export const isBackspace = ({ keyCode }) => keyCode === 8;
