import { call, put, all, takeLatest } from 'redux-saga/effects';
import { actions } from './AccountCreation.slice';
import { actions as authActions } from '../Auth/Auth.slice';
import {
  createAccount as createAccountService,
  getLeads,
  getSalesRanges
} from '../../services/accountCreation.service';
import { ILeads, ISalesRange, ResponseErrors } from '../../types/Interface';

function* setSalesRangeFn({ payload }: ReturnType<typeof actions.setSalesRange>) {
  try {
    const { countryCode } = payload;
    const data: ISalesRange[] | ResponseErrors = yield call(getSalesRanges, countryCode);
    if (data) {
      yield put(actions.setSalesRangeSuccess({ data: data as ISalesRange[] }));
    } else {
      const { message } = data;
      throw Error(message);
    }
  } catch (error) {
    yield put(actions.setRegisterFailure({ error: (error as Error).message }));
  }
}

function* setRegisterFn({ payload }: ReturnType<typeof actions.setRegister>) {
  try {
    const { newAccount } = payload;
    const leads: ILeads | ResponseErrors = yield call(createAccountService, newAccount);
    const { id } = leads as ILeads;
    if (id) {
      yield put(actions.setRegisterSuccess({ leads: leads as ILeads }));
      yield put(authActions.setBusinessEmail({ businessEmail: newAccount.businessEmail }));
    } else {
      const { message } = leads as ResponseErrors;
      if (message.includes('Email exists before')) {
        yield put(authActions.setIsExistedEmail({ isExistedEmail: message }));
      }
      throw Error(message);
    }
  } catch (error) {
    yield put(actions.setRegisterFailure({ error: (error as Error).message }));
  }
}

function* setLeadsFn() {
  try {
    const leads: ILeads | ResponseErrors = yield call(getLeads);
    const { id } = leads as ILeads;

    if (id) {
      yield put(actions.setLeadsSuccess({ leads: leads as ILeads }));
    } else {
      const { message } = leads as ResponseErrors;
      throw Error(message);
    }
  } catch (error) {
    yield put(actions.setLeadsFailure({ error: (error as Error).message }));
  }
}

function* watchSetSalesRange() {
  yield takeLatest(actions.setSalesRange, setSalesRangeFn);
}

function* watchSetRegister() {
  yield takeLatest(actions.setRegister, setRegisterFn);
}

function* watchSetLeads() {
  yield takeLatest(actions.setLeads, setLeadsFn);
}

export default function* AccountCreationSagas() {
  yield all([call(watchSetRegister), call(watchSetSalesRange), call(watchSetLeads)]);
}
