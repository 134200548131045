import React, { Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';

import { PagesVisitAnalytic } from './analytics';
import Spinner from './components/Spinner';
import { withLaunchDarkly } from './analytics/launchDarkly';
import { ChakraProvider } from '@chakra-ui/react';
import theme from './assets/theme';
import './i18n';
import './assets/styles/index.scss';
import 'flag-icons';
import SpinnerProvider from './providers/SpinnerProvider';
import store from './redux/store';
import Routes from './routesCollection/Routes';

import { createBrowserHistory } from 'history';

const Main = () => (
  <ChakraProvider theme={theme}>
    <SpinnerProvider>
      <Suspense fallback={<Spinner open={true} />}>
        <Provider store={store}>
          <BrowserRouter>
            <PagesVisitAnalytic>
              <Routes />
            </PagesVisitAnalytic>
          </BrowserRouter>
        </Provider>
      </Suspense>
    </SpinnerProvider>
  </ChakraProvider>
);

const history = createBrowserHistory();
const LDProvider = withLaunchDarkly(Main);
const root = createRoot(document.getElementById('root') as HTMLElement);

if (root) {
  // 1. Set up the browser history with the updated location
  // (minus the # sign)
  const path = (/#!(\/.*)$/.exec(location.hash) || [])[1];
  if (path) {
    history.replace(path);
  }

  // 2. Render our app
  root.render(<LDProvider />);
}
