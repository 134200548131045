export const loadState = (name: string) => {
  try {
    const serializedState = localStorage.getItem(name);
    if (serializedState === null) return null;
    return JSON.parse(serializedState);
  } catch (e) {
    return null;
  }
};

export const saveState = (name: string, state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(name, serializedState);
  } catch (e) {
    return null;
  }
};
