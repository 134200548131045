import { defineStyleConfig } from '@chakra-ui/react';

// define the base component styles
const baseStyle = {
  display: 'flex',
  justifyContent: 'start',
  alignItems: 'center',
  height: '46px',
  minWidth: '170px',
  width: 'auto',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '20px',
  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.1)',
  borderRadius: '8px'
};

// export the component theme
export default defineStyleConfig({ baseStyle });
