export const colors = {
  dark: {
    50: '#F9F9F9',
    100: '#F9F9F9',
    200: '#F4F4F4',
    300: '#D1D2D4',
    400: '#86898F',
    500: '#000',
    600: '#262C36',
    700: '#373C46',
    800: '#262C36',
    900: '#000'
  },

  brand: {
    yellow: '#FFE600',
    teal: '#00AEBA',
    blue: '#0C3FEA',
    coral: '#FF8C8C'
  },

  primary: {
    50: '#EFFBF8',
    100: '#EFFBF8',
    200: '#DFF7F1',
    300: '#8ED6C7',
    400: '#309597',
    500: '#317F81',
    600: '#256674',
    700: '#1D525D',
    800: '#13333A',
    900: '#13333A'
  },

  neutral: {
    50: '#FFFFFF',
    100: '#F9F9F9',
    200: '#F4F4F4',
    300: '#D1D2D4',
    400: '#86898F',
    500: '#73777D',
    600: '#5F636B',
    700: '#373C46',
    800: '#262C36',
    900: '#000000'
  },

  red: {
    50: '#fff8f8',
    100: '#fff8f8',
    200: '#fceae9',
    300: '#f3ada2',
    400: '#e95046',
    500: '#d73f35',
    600: '#b33733',
    700: '#952635',
    800: '#5b1932',
    900: '#5b1932'
  },

  orange: {
    50: '#FEF9F7',
    100: '#FEF9F7',
    200: '#FCEDE5',
    300: '#F6C79B',
    400: '#E25F28',
    500: '#C15113',
    600: '#AB3D0D',
    700: '#863523',
    800: '#592611',
    900: '#000584'
  },

  yellow: {
    50: '#fef9ec',
    100: '#fef9ec',
    200: '#fdf3dc',
    300: '#e7d188',
    400: '#ba7c24',
    500: '#9e6d27',
    600: '#8e5123',
    700: '#76441e',
    800: '#402805',
    900: '#402805'
  },

  green: {
    50: '#f4fbee',
    100: '#f4fbee',
    200: '#eaf7df',
    300: '#a7e1a8',
    400: '#3a9c3b',
    500: '#3e823e',
    600: '#176d49',
    700: '#1d593f',
    800: '#1a302f',
    900: '#1a302f'
  },

  teal: {
    50: '#EFFBF8',
    100: '#EFFBF8',
    200: '#DFF7F1',
    300: '#8ED6C7',
    400: '#309597',
    500: '#317F81',
    600: '#256674',
    700: '#1D525D',
    800: '#13333A',
    900: '#13333A'
  },

  blue: {
    50: '#f2f7fd',
    100: '#f2f7fd',
    200: '#ecf4fc',
    300: '#becfe9',
    400: '#5b84dc',
    500: '#3070d7',
    600: '#3c54cd',
    700: '#3346a3',
    800: '#122e4f',
    850: '#000584',
    900: '#122e4f'
  },

  purple: {
    50: '#f7f7fd',
    100: '#f7f7fd',
    200: '#eeebfa',
    300: '#cfc8f9',
    400: '#7d75e1',
    500: '#6f5fd8',
    600: '#613fd9',
    700: '#4d4091',
    800: '#2f285c',
    900: '#2f285c'
  },

  magenta: {
    50: '#FCF8FA',
    100: '#FCF8FA',
    200: '#F5EAF2',
    300: '#ECC5DF',
    400: '#EA4D8F',
    500: '#C7417A',
    600: '#AD2D72',
    700: '#80286D',
    800: '#511A4A',
    900: '#511A4A'
  },

  sidebar: '#262C36'
};
