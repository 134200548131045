import { useContext, useEffect, useMemo } from 'react';
import { SpinnerContext } from '../context/Spinner';

export const useSpinner = (isOpen = false) => {
  const { open, close } = useContext(SpinnerContext);

  useEffect(() => {
    isOpen && open();
  }, [isOpen, open]);

  const backdrop = useMemo(
    () => ({
      open,
      close
    }),
    [open, close]
  );

  return backdrop;
};
