export const initialize = id => {
  const initAnalytics = document.createElement('script');
  const loadLibrary = document.createElement('script');

  initAnalytics.text = `
    window.ga=window.ga||function(){(ga.q=ga.q||[]).push(arguments)};
    ga.l=+new Date;
    ga('create', '${id}', 'auto');
    ga('send', 'pageview');`;

  loadLibrary.type = 'text/javascript';
  loadLibrary.async = true;
  loadLibrary.src = 'https://www.google-analytics.com/analytics.js';

  document.head.appendChild(initAnalytics);
  document.head.appendChild(loadLibrary);
};

export const sendVisitedPage = page => {
  if (window.ga) {
    window.ga('set', 'page', page);
    window.ga('send', 'pageview');
  }
};
