import React, { useEffect, useRef, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import AsyncCreatable from 'react-select/async-creatable';
import AsyncSelect from 'react-select/async';
import Select, { components as defaultComponents } from 'react-select';
import { FixedSizeList } from 'react-window';
import { t } from 'i18next';
import { ReactComponent as SelectDropdownIndicator } from '../../assets/images/dropdown-arrows.svg';
import { ReactComponent as CheckIcon } from '../../assets/images/check.svg';
import { colors } from '../../assets/theme/foundations/colors';
import { shadows } from '../../assets/theme/foundations/shadows';
import './Select.scss';
import { useFormContext } from 'react-hook-form';
import { FormControl, FormErrorMessage, Icon } from '@chakra-ui/react';
import { ReactComponent as AlertIcon } from '../../assets/images/alert.svg';
import PropTypes from 'prop-types';

const selectComponents = {
  default: Select,
  async: AsyncSelect,
  creatable: CreatableSelect,
  asyncCreatable: AsyncCreatable
};

const OPTION_HEIGHT = 36;

const DropdownIndicator = props => (
  <defaultComponents.DropdownIndicator {...props}>
    <SelectDropdownIndicator />
  </defaultComponents.DropdownIndicator>
);

const Input = props => (
  <defaultComponents.Input {...props} type="search" autoComplete="off" aria-autocomplete="none" />
);

const VirtualizedMenuList = ({ children, maxHeight }) => {
  const items = Array.isArray(children) ? children : [children];
  const listHeight = Math.min(items.length * OPTION_HEIGHT, maxHeight);

  return (
    <FixedSizeList
      height={listHeight}
      itemCount={items.length}
      itemSize={OPTION_HEIGHT}
      className="select__virtualized-menu-list"
    >
      {({ index, style }) => <div style={style}>{items[index]}</div>}
    </FixedSizeList>
  );
};

const Option = ({ children, ...restProps }) => {
  const contentRef = useRef(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    if (contentRef.current?.offsetWidth < contentRef.current?.scrollWidth) {
      setIsTruncated(true);
    }
  }, [contentRef.current]);

  return (
    <defaultComponents.Option {...restProps}>
      <div
        title={isTruncated ? restProps.data.label : undefined}
        ref={contentRef}
        className={'select__option'}
      >
        {children}
        {restProps.isSelected && <CheckIcon />}
      </div>
    </defaultComponents.Option>
  );
};

export const CustomSelect = ({
  selectType,
  isDisabled = false,
  hideIndicator = undefined,
  name,
  value,
  onChange,
  onBlur,
  components = undefined,
  styles = undefined,
  withTopItemSeparator = undefined,
  showDefaultDropdownIndicator,
  withVirtualScroll = undefined,
  isInvalid,
  validation = {},
  ...props
}) => {
  const [fieldTouched, setFieldTouched] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const Component = selectComponents[selectType];
  const methods = useFormContext();
  const getDropdownIndicator = () => {
    if (hideIndicator) return null;
    if (showDefaultDropdownIndicator) return defaultComponents.DropdownIndicator;
    return DropdownIndicator;
  };

  const customComponents = {
    ClearIndicator: null,
    IndicatorSeparator: null,
    Input,
    Option,
    DropdownIndicator: getDropdownIndicator(),
    ...(withVirtualScroll ? { MenuList: VirtualizedMenuList } : {}),
    ...components
  };

  const selectTopSeparatorStyles = withTopItemSeparator
    ? {
        '&:first-of-type': {
          borderBottom: `1px solid ${colors.bg500} !important`
        },
        '&:only-child': {
          padding: 0,
          borderBottom: `none !important`
        },
        '> div:first-of-type': {
          display: 'none'
        }
      }
    : null;

  const customStyles = {
    control: (provided, prop) => {
      const { isFocused } = prop;
      const isNotValid = (!methods.getValues()[name] && fieldTouched) || !!isInvalid;
      return {
        ...provided,
        boxSizing: 'border-box',
        border: isNotValid
          ? `2px solid ${colors.red['500']}`
          : `1px solid ${colors.neutral['300']}`,
        backgroundColor: isDisabled ? colors.neutral['200'] : colors.neutral['50'],
        borderRadius: '8px',
        boxShadow: 'none',
        height: '40px',
        alignContent: 'center',
        '&:hover': {
          border: isNotValid ? '' : `2px solid ${colors.teal['400']}`
        },

        ...(isFocused ? { boxShadow: isNotValid ? shadows.invalidField : shadows.validField } : {}),

        ' > div': {
          paddingLeft: '12px'
        }
      };
    },
    input: provided => ({
      ...provided,
      boxSizing: 'border-box',
      fontFamily: 'PublicSansSemiBold',
      fontSize: 14,
      color: colors.text800
    }),
    valueContainer: provided => {
      return {
        ...provided,
        display: 'grid',
        gridTemplateColumns: '70% auto'
      };
    },
    option: (provided, { isFocused, isSelected }) => {
      const backgroundColor = (() => {
        if (isSelected) return colors.teal['600'];
        if (isFocused && !isSelected) return colors.neutral['300'];
        return 'none';
      })();

      return {
        ...provided,
        ':active': {
          backgroundColor: 'none'
        },
        '&:not(:last-child)': {
          marginBottom: '2px'
        },
        borderRadius: '4px',
        height: OPTION_HEIGHT,
        backgroundColor
      };
    },
    singleValue: provided => ({
      ...provided,
      color: colors.text800,
      position: 'static',
      transform: 'none',
      fontSize: 14,
      lineHeight: '15px',
      fontFamily: 'PublicSansSemiBold',
      marginLeft: 0
    }),
    placeholder: provided => ({
      ...provided,
      width: '93%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      fontSize: 14,
      whiteSpace: 'nowrap',
      fontFamily: 'PublicSansSemiBold'
    }),
    menu: provided => ({
      ...provided,
      borderRadius: '8px',
      border: `1px solid ${colors.neutral['300']}`,
      boxShadow: '0 2px 8px rgba(0,0,0,0.1)'
    }),
    menuList: provided => ({
      ...provided,
      padding: '6px',

      '[class$="Group"]:first-of-type': {
        paddingTop: '0px',
        borderBottom: `1px dotted ${colors.bg500}`
      },

      '&::-webkit-scrollbar': {
        width: '5px'
      },
      '&::-webkit-scrollbar-thumb': {
        backgroundColor: colors.neutral['400'],
        borderRadius: '10px'
      }
    }),
    dropdownIndicator: provided => {
      return {
        ...provided,

        '&:hover': {
          'svg path': {
            stroke: colors.text600
          }
        }
      };
    },
    multiValueLabel: provided => ({
      ...provided,
      fontFamily: 'PublicSansSemiBold'
    }),
    group: provided => ({
      ...provided,
      ...selectTopSeparatorStyles
    }),
    ...styles
  };

  const handleOnChange = value => {
    methods.setValue(name, value);
    setFieldTouched(true);
    if (onChange) {
      return onChange(value);
    }
  };

  const handleInputBlur = () => {
    setFieldTouched(true);
    if (onBlur) {
      return onBlur(name);
    }
  };
  const onInputChange = value => {
    if (new RegExp(/^[a-zA-Z0-9_.-]*$/).test(value)) {
      setInputValue(value);
    }
  };
  return (
    <>
      <FormControl mb="0" isInvalid={!!isInvalid}>
        <Component
          inputValue={inputValue}
          onInputChange={onInputChange}
          isDisabled={isDisabled}
          components={customComponents}
          styles={customStyles}
          // {...methods}
          {...props}
          name={name}
          {...methods.register(name, isDisabled ? {} : validation)}
          onChange={value => handleOnChange(value, name)}
          onBlur={() => handleInputBlur(name)}
          value={value}
        />

        <FormErrorMessage fontSize=".9rem" textAlign="start">
          <Icon fontSize="1rem" mr=".2rem" mb=".3rem" as={AlertIcon} />
          {t(isInvalid?.message)}
        </FormErrorMessage>
      </FormControl>
    </>
  );
};
CustomSelect.propTypes = {
  selectType: PropTypes.string,
  isDisabled: PropTypes.bool,
  hideIndicator: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.any,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  components: PropTypes.object,
  styles: PropTypes.object,
  withTopItemSeparator: PropTypes.bool,
  showDefaultDropdownIndicator: PropTypes.bool,
  withVirtualScroll: PropTypes.bool,
  isInvalid: PropTypes.object,
  validation: PropTypes.object
};

VirtualizedMenuList.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.arrayOf(PropTypes.element)]),
  maxHeight: PropTypes.number
};

Option.propTypes = {
  children: PropTypes.node
};
