import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { selectAccountCreation } from '../../redux/AccountCreation/AccountCreation.selectors';

import { Button, Flex } from '@chakra-ui/react';
import { amplitude } from '../../utils/amplitude';
import useActions from '../../hooks/useActions';
import { JOIN } from '../../constants/routes.constants';

import { Content } from './components/Content/Content';
import { HeadLine } from './components/HeadLine/HeadLine';
import { Layout } from '../../components/LayoutComponents';

// eslint-disable-next-line
export default function Rejection(props: { revenueIssue: any }) {
  const { t } = useTranslation('rejection');
  const { resetState } = useActions();
  const { leads, isLeadsSuccess } = useSelector(selectAccountCreation);

  const hasRouteValidation = leads && leads?.qualified === false;
  const renderRejection = parseInt(localStorage.getItem('renderRejection') || '0');

  const handleOpenLinkedinPage = () => {
    window.open('https://www.linkedin.com/company/tribal-credit', '_blank');
  };

  // Amplitude event
  useEffect(() => {
    if (renderRejection && renderRejection > 0) {
      resetState();
    }

    if (hasRouteValidation) {
      localStorage.setItem('renderRejection', String(renderRejection + 1));
      amplitude.track({ name: 'Rejection Page Viewed' });
    }
  }, [hasRouteValidation, renderRejection]); //eslint-disable-line react-hooks/exhaustive-deps

  // Validate if user hasn't been approved yet,
  // otherwise redirect route
  if (!hasRouteValidation) {
    localStorage.removeItem('renderRejection');
    return <Navigate replace to={JOIN} />;
  }

  return (
    <Layout>
      <Flex margin="auto" direction="column" align="center">
        <HeadLine />
        <Content revenueIssue={props.revenueIssue} />
        <Button
          onClick={handleOpenLinkedinPage}
          variant="neutral"
          size="medium"
          mt="8"
          fontFamily="body"
        >
          {t('title.goToLinkedin')}
        </Button>
      </Flex>
    </Layout>
  );
}
