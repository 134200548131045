import { useTranslation, Trans } from 'react-i18next';
import { FormProvider, useForm } from 'react-hook-form';
import React, { useRef, useState } from 'react';
import { SAT_PASSWORD_RESET_URL, SAT_YOUTUBE_URL } from '../../../../constants/externalLinks';
import { Grid, GridItem, Button, useToast, Text, Link, Flex } from '@chakra-ui/react';
import Input from '../../../../components/Input';
import { SATValues } from '../../../../types/Interface';
import { useLocation } from 'react-router-dom';
import { fieldsValidation } from '../../../../constants/validation';
import { getCredentials, createCredential } from '../../../../services/sat.service';
import { SatLoader } from '../SatLoader/SatLoader';
import './SatForm.scss';

interface ISatForm {
  setSatConnected: (state: boolean) => void;
  setTimeoutError: (state: boolean) => void;
  timeoutError: boolean;
}

export const SatForm = ({ setSatConnected, setTimeoutError, timeoutError }: ISatForm) => {
  const { t } = useTranslation('satConnect');

  const methods = useForm<SATValues>();
  const password = methods.watch('password');
  const { search } = useLocation();
  const RFC = new URLSearchParams(search).get('rfc');
  const companyId = new URLSearchParams(search).get('companyid');
  const toast = useToast();

  const [loader, setLoader] = useState<boolean>(false);
  const [show, setShow] = useState(false);

  const intervalRef = useRef<any>(null);
  const maxAttemps = 10;
  const seconds = 4000;

  // eslint-disable-next-line
  const prepareInputValidation = (validation: any) => {
    return {
      ...validation,
      onChange: async () => await methods.trigger('password'),
      onBlur: async () => await methods.trigger('password')
    };
  };

  const onSubmit = () => {
    setLoader(() => true);
    createCredential({ type: 'ciec', rfc: RFC, companyId: companyId, password: password })
      .then(() => getUpdatedCredentials())
      .catch(res => {
        stopGettingCredentials();
        if (res?.response?.data?.message === 'The RFC already exists to another Company') {
          toast({
            position: 'top-right',
            title: t(`invalidCIEForCompany`),
            status: 'error',
            duration: 4000,
            isClosable: true
          });
        } else {
          toast({
            position: 'top-right',
            title: t(`invalidCIECorRfc`),
            status: 'error',
            duration: 4000,
            isClosable: true
          });
        }
        intervalRef.current = 'fail';
      });
  };

  const getUpdatedCredentials = () => {
    let attempt = 0;

    intervalRef.current = setInterval(() => {
      attempt++;
      getCredentials(RFC || '')
        .then(res => {
          if (res?.data?.status === 'valid') {
            setSatConnected(true);
            stopGettingCredentials();
            intervalRef.current = 'success';
          } else if (res?.data?.status === 'invalid') {
            toast({
              position: 'top-right',
              title: t(`invalidCIECError`),
              status: 'error',
              duration: 4000,
              isClosable: true
            });
            stopGettingCredentials();
            intervalRef.current = 'fail';
            methods.setError('password', fieldsValidation.password.minLength);
          } else if (attempt === maxAttemps) {
            setTimeoutError(true);
            clearInterval(intervalRef.current);
            intervalRef.current = 'timeout';
          }
        })
        .catch(res => {
          stopGettingCredentials();
          toast({
            position: 'top-right',
            title: res?.response?.data?.message,
            status: 'error',
            duration: 4000,
            isClosable: true
          });
          intervalRef.current = 'fail';
        });
    }, seconds);
  };

  const stopGettingCredentials = () => {
    setLoader(false);
    clearInterval(intervalRef.current);
  };

  return !loader ? (
    <FormProvider {...methods}>
      <form className="form-container">
        <Grid rowGap="0.5rem">
          <GridItem>
            <Input label={t('labels.rfc')} stateName="RFC" value={RFC || ''} isDisabled />
          </GridItem>
          <GridItem>
            <Input
              label={t('labels.ciec')}
              stateName="password"
              type={show ? 'text' : 'password'}
              show={show}
              setShow={setShow}
              toolTipTxt={<Trans t={t} i18nKey={t('passwordTooltip')} />}
              maxLength={8}
              isInvalid={methods.formState.errors.password}
              validation={prepareInputValidation(fieldsValidation.password)}
            />
          </GridItem>
        </Grid>
        <Flex justifyContent="end">
          <Link
            href={SAT_PASSWORD_RESET_URL}
            isExternal
            target="_blank"
            _hover={{ textDecoration: 'none' }}
          >
            <Text className="form-container__forgetPassword">{t('forgetPassword')}</Text>
          </Link>
        </Flex>
        <Button
          onClick={onSubmit}
          disabled={!(password?.length === 8)}
          size="sm"
          className="form-container__submit"
        >
          {t('connect')}
        </Button>
        <Trans
          t={t}
          i18nKey={t('externalDisclaimer')}
          components={[
            <Text
              fontFamily="PublicSansRegular"
              fontSize="10px"
              color="neutral.800"
              mt="2rem"
              lineHeight="14px"
              textAlign="center"
              key="description"
            />,
            <Link
              href={SAT_YOUTUBE_URL}
              isExternal
              target="_blank"
              fontSize="10px"
              textAlign="center"
              lineHeight="14px"
              textDecoration="underline"
              color="teal.500"
              textUnderlineOffset="2px"
              key="link"
              mb="1rem"
            />
          ]}
        />
      </form>
    </FormProvider>
  ) : (
    <SatLoader timeout={timeoutError} setLoader={setLoader} setTimeoutError={setTimeoutError} />
  );
};
