import { withLDProvider } from 'launchdarkly-react-client-sdk';

const generateId = () => Math.random().toString(16).slice(2);

const getUserId = () => {
  const STORAGE_KEY = 'LD_userId';
  const userId = localStorage.getItem(STORAGE_KEY);

  if (userId) return userId;

  const generatedId = generateId();
  localStorage.setItem(STORAGE_KEY, generatedId);

  return generatedId;
};

const getAnonymousUserData = () => ({
  anonymous: true,
  key: 'anonymous',
  custom: {
    userId: getUserId()
  }
});

export const withLaunchDarkly = component =>
  withLDProvider({
    clientSideID: process.env.REACT_APP_LAUNCHDARKLY_CLIENT_ID,
    user: getAnonymousUserData(),
    deferInitialization: true
  })(component);
