import { BUSINESS_HQ_CODE } from '../../../constants/businessHQ';
import i18n from '../../../i18n';
import { LANGUAGES } from '../../../constants/languages';

const { BR, CL, PE, CO, MX } = BUSINESS_HQ_CODE;

const getTranslationKeyWithHQ = (key, businessHQ) => {
  if (businessHQ === MX) {
    return `${key}_MX`;
  }
  if ([CO, CL, PE].includes(businessHQ)) {
    return `${key}_CO`;
  }
  if (businessHQ === BR) {
    return `${key}_BR`;
  }
};

const isLATAMCountry = businessHQ => [MX, CO, CL, PE, BR].includes(businessHQ);

const getRegistrationQuestionTitle = businessHQ => {
  let key = 'registeredBusiness';

  if (isLATAMCountry(businessHQ)) {
    key =
      businessHQ === BR && i18n.language === LANGUAGES.PT
        ? 'incorporatedCompany_BR'
        : 'incorporatedCompany';
  }

  return i18n.t(`title.${key}`, { ns: 'createAccount' });
};

const getRegistrationQuestionOptions = businessHQ => [
  {
    label: i18n.t(
      isLATAMCountry(businessHQ)
        ? getTranslationKeyWithHQ('createAccount:label.isRegisteredBusiness', businessHQ)
        : 'translation:label.yes'
    ),
    value: 'true'
  },
  {
    label: i18n.t(
      isLATAMCountry(businessHQ)
        ? getTranslationKeyWithHQ('createAccount:label.isNotRegisteredBusiness', businessHQ)
        : 'translation:label.no',
      {}
    ),
    value: 'false'
  }
];

export { getRegistrationQuestionTitle, getRegistrationQuestionOptions };
