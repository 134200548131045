import { call, put, all, takeLatest } from 'redux-saga/effects';
import { actions } from './UserData.slice';
import {
  createProfile,
  submitApplication,
  createApplicationParticipants
} from '../../services/userdata.service';

function* createProfileFn({ payload }: ReturnType<typeof actions.setProfileCreation>) {
  try {
    const response: { status: number; data: { id: string }; message: string } = yield call(
      createProfile,
      payload
    );

    if (response?.status < 300) {
      yield put(actions.setProfileCreationSuccess({ id: response.data.id }));
    } else {
      yield put(
        actions.setProfileCreationFailure({
          error: response.message
        })
      );
    }
  } catch (e) {
    yield put(
      actions.setProfileCreationFailure({
        error: (e as Error).message
      })
    );
  }
}

function* createApplicationParticipantsFn({
  payload
}: ReturnType<typeof actions.setApplicationParticipants>) {
  try {
    const response: { status: number; data: { id: string }; message: string } = yield call(
      createApplicationParticipants,
      payload
    );

    if (response?.status < 300) {
      yield put(actions.setApplicationParticipantsSuccess({ id: response.data.id }));
    } else {
      yield put(
        actions.setApplicationParticipantsFailure({
          error: response.message
        })
      );
    }
  } catch (e) {
    yield put(
      actions.setApplicationParticipantsFailure({
        error: (e as Error).message
      })
    );
  }
}

function* submitApplicationFn({ payload }: ReturnType<typeof actions.setSubmitApplication>) {
  try {
    const response: { status: number; message: string } = yield call(submitApplication, payload);

    if (response?.status < 300) {
      yield put(actions.setSubmitApplicationSuccess());
    } else {
      yield put(
        actions.setSubmitApplicationFailure({
          error: response.message
        })
      );
    }
  } catch (e) {
    yield put(
      actions.setSubmitApplicationFailure({
        error: (e as Error).message
      })
    );
  }
}

function* watchCreateProfileFn() {
  yield takeLatest(actions.setProfileCreation, createProfileFn);
}

function* watchSubmitApplicationFn() {
  yield takeLatest(actions.setSubmitApplication, submitApplicationFn);
}

function* watchCreateApplicationParticipantsFn() {
  yield takeLatest(actions.setApplicationParticipants, createApplicationParticipantsFn);
}

export default function* UserDataSagas() {
  yield all([
    call(watchCreateProfileFn),
    call(watchSubmitApplicationFn),
    call(watchCreateApplicationParticipantsFn)
  ]);
}
