import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate, Navigate, useSearchParams } from 'react-router-dom';
import { Box, Heading, Text, useToast } from '@chakra-ui/react';
import { isAccountRejected, isRegisteredCompany, prepareDataForBackend } from './utils';
import { CreateAccountForm } from './components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Layout } from '../../components/LayoutComponents';
import { ILeads, RegistrationForm } from '../../types/Interface';
import { selectAccountCreation } from '../../redux/AccountCreation/AccountCreation.selectors';
import useActions from '../../hooks/useActions';
import { useSpinner } from '../../hooks/useSpinner';
import * as gclid from '../../analytics/gclid';
import { BUSINESS_PROFILE, REJECTION, WAITLIST } from '../../constants/routes.constants';
import { sendAgreement } from '../../utils/clickwrap';
import { amplitude } from '../../utils/amplitude';
import { device } from '../../constants/userDevice';

const CreateAccount = () => {
  const toast = useToast();
  const spinner = useSpinner();
  const navigate = useNavigate();
  const { pocRegistrationPage, experiment } = useFlags();
  const { setRegister, setRegisterFailure, setGroupKey, setAmplitudeAccount } = useActions();
  const { isLeadsSuccess, leads, isLoading, accountCreationError, isCreatingAccount, groupKey } =
    useSelector(selectAccountCreation);
  const {
    t,
    i18n: { language }
  } = useTranslation('createAccount');

  const [userData, setUserData] = useState<RegistrationForm>();
  const [searchParams] = useSearchParams();

  const entries: any = searchParams.entries();
  const utmParams: Record<string, string> = {};
  for (const entry of entries) {
    // nosemgreps
    utmParams[entry[0]] = entry[1];
  }

  const WAITLISTED_STATUS = 'WAITLISTED';
  const AUTO_REJECTED_STATUS = 'AUTO_REJECTED';
  const hasRouteValidation = !isLeadsSuccess && Object.keys(leads).length === 0;

  const createUser = (values: RegistrationForm) => {
    const languageId = language.toLocaleUpperCase();

    const newAccount = prepareDataForBackend({
      ...values,
      languageId,
      isTestFlagEnabled: pocRegistrationPage,
      utm: JSON.stringify(utmParams),
      originChannel: 'signup_inbound'
    });

    setRegister({
      newAccount: newAccount,
      isAccountRejected: !isAccountRejected(values),
      registerForm: { ...newAccount, countryHq: values.businessHQ!.label }
    });

    gclid.clear();
  };

  const handleSubmit = async (values: RegistrationForm) => {
    setUserData(values);
  };

  const amplitudeTracking = (leads: ILeads, userData: RegistrationForm) => {
    // Set amplitude account data
    amplitude.setGroupId(leads.id);
    amplitude.groupIdentify(leads.id, {
      'business hq': userData!.businessHQ?.code as string,
      'registered business': isRegisteredCompany(userData!.isRegisteredCompany),
      'average monthly sales': userData.monthlySales!.amplitudeValue as string,
      'lead id': leads.id
    });

    // Save in persist AccountCreation state to keep data after refresh
    setAmplitudeAccount({
      amplitudeAccount: {
        businessHQ: userData!.businessHQ?.code as string,
        registeredBusiness: isRegisteredCompany(userData!.isRegisteredCompany),
        averageMonthlySales: userData.monthlySales!.amplitudeValue as string,
        leadId: leads.id
      }
    });

    // Amplitude event
    amplitude.track({ name: 'Registration Completed' });
  };

  useEffect(() => {
    if (hasRouteValidation) amplitude.track({ name: 'Registration Page Viewed' });
  }, [hasRouteValidation]);

  useEffect(() => {
    if (userData) createUser(userData);
  }, [userData]);

  useEffect(() => {
    // nosemgrep
    if (isCreatingAccount || isLoading) spinner.open();

    return () => {
      spinner.close();
    };
  }, [isCreatingAccount, isLoading]);

  useEffect(() => {
    const approved = isLeadsSuccess && leads?.qualified;
    const waitlisted =
      leads &&
      isCreatingAccount &&
      leads?.qualified === false &&
      leads?.preQualificationStatus === WAITLISTED_STATUS;
    const autoRejected =
      leads &&
      isCreatingAccount &&
      leads?.qualified === false &&
      leads?.preQualificationStatus === AUTO_REJECTED_STATUS;

    const sentToClickWrap = async (groupKey: string) => {
      await sendAgreement(groupKey);
    };

    if (approved) {
      if (userData) amplitudeTracking(leads, userData);

      // Send and download agreement
      if (groupKey) sentToClickWrap(groupKey);

      // Clear groupKey
      setGroupKey({ groupKey: '' });

      return navigate(BUSINESS_PROFILE);
    }

    // If waitlisted, redirect to Waitlist Thank You Page
    if (waitlisted) return navigate(WAITLIST);

    // If autoRejected, redirect to Reject Thank You Page
    if (autoRejected) return navigate(REJECTION);
  }, [isLeadsSuccess, leads, isCreatingAccount]);

  useEffect(() => {
    if (accountCreationError.length > 0) {
      let error =
        accountCreationError === 'Phone exists before'
          ? t('error.phoneNumberExists')
          : accountCreationError === 'Email exists before'
          ? t('error.emailExists')
          : accountCreationError;
      toast({
        title: `${error}`,
        status: 'error',
        duration: 4000,
        position: 'top-right',
        isClosable: true
      });

      // Clean error after showing it
      setRegisterFailure({ error: '' });
    }
  }, [accountCreationError]);

  // Validate if user hasn't been approved yet,
  // otherwise redirect route
  if (!hasRouteValidation) {
    return <Navigate replace to={BUSINESS_PROFILE} />;
  }

  return (
    <Layout>
      <Box margin="auto" w={{ base: '90%', md: '626px' }}>
        <Heading
          as="h1"
          px={6}
          fontFamily="Public Sans"
          fontWeight="700"
          fontSize={device === 'mobile' ? '2.5rem' : '3.5rem'}
          textAlign="center"
          lineHeight={device === 'mobile' ? 'initial' : 'auto'}
        >
          {t('title.join')}
        </Heading>
        <Text
          mt="30px"
          mb={device === 'mobile' ? '50px' : '90px'}
          fontFamily="Public Sans"
          fontSize="18px"
          fontWeight="400"
          textAlign="center"
        >
          {t('description.join')}
        </Text>

        <CreateAccountForm onSubmit={handleSubmit} />
        {device === 'mobile' && (
          <Text
            textAlign="center"
            mt="1rem"
            color="gray.500"
            fontFamily="Public Sans"
            fontSize="14px"
          >
            {t('label.loggedOut')} {}
            <a
              href={`https://app.tribalcredit.io/login?${language}`}
              style={{ color: 'teal', textDecoration: 'underline' }}
            >
              {t('translation:label.signin')}
            </a>
          </Text>
        )}
      </Box>
    </Layout>
  );
};

export default CreateAccount;
