import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FormLabel, Box, Text } from '@chakra-ui/react';
import Select from '../CustomSelect';
import { getIndustryOptions, getSectorOptions, sortOptionsByLabel } from './utils';
import { useSectorsAndIndustries } from './hooks/useSectorsAndIndustries';
import './SectorAndIndustryFields.scss';
import { useFormContext } from 'react-hook-form';
import PropTypes from 'prop-types';

const SectorAndIndustryFields = ({
  onSelectBlur,
  invalidSector,
  invalidIndustry,
  industryValidation,
  sectorValidation,
  onChange,
  isRequired
}) => {
  const [sectorValue, setSectorValue] = useState();
  const [industryValue, setIndustryValue] = useState();
  const methods = useFormContext();
  const {
    t,
    i18n: { language }
  } = useTranslation('createAccount');
  const { data: sectors, isLoaded: isSectorsLoaded } = useSectorsAndIndustries(language);

  const handleSectorChange = async value => {
    await methods.trigger('sectors');
    methods.setValue('sectors', value);
    onChange(value, 'sectors');
    setSectorValue(value);

    if (value.industries.length === 1) {
      let industry = { label: value.industries[0].name, value: value.industries[0].industry };
      setIndustryValue(industry);

      methods.setValue('industry', industry);
      methods.trigger('industry');
    } else {
      setIndustryValue(null);
      methods.setValue('industry', null);
    }
  };
  const handleIndustryChange = value => {
    methods.trigger('industry');
    onChange(value, 'industry');
    setIndustryValue(value);
    methods.setValue('industry', value);
  };
  const getSelectedSector = () =>
    sectors.find(({ name }) => name === methods.getValues().sectors.label);

  useEffect(() => {
    const currentSector = sectors?.find(elm => elm.sector === sectorValue?.value),
      currentIndustryId = industryValue?.value;
    if (currentSector) {
      const currentIndustry = currentSector.industries.find(
          elm => elm.industry === currentIndustryId
        ),
        mappedSector = {
          value: currentSector.sector,
          label: currentSector.name,
          industries: currentSector.industries
        },
        mappedIndustry = { value: currentIndustry.industry, label: currentIndustry.name };
      methods.setValue('sectors', mappedSector);
      onChange(mappedSector, 'sectors');
      setSectorValue(mappedSector);
      mappedIndustry && handleIndustryChange(mappedIndustry);
    }
  }, [sectors]);

  return (
    <Box>
      <Box mt="1.5rem">
        <FormLabel fontSize="0.875rem" mt=".4rem" as="span">
          {t('startupProfile:label.sectors')}
          {isRequired && (
            <Text color="red.500" as="span" lineHeight={'0rem'}>
              {' '}
              *
            </Text>
          )}
        </FormLabel>
        <Select
          onBlur={onSelectBlur}
          selectType="default"
          onChange={handleSectorChange}
          options={sortOptionsByLabel(getSectorOptions(sectors))}
          name="sectors"
          placeholder=""
          type="text"
          value={sectorValue}
          noOptionsMessage={() => null}
          loadingMessage={() => t('label.loading')}
          isLoading={!isSectorsLoaded}
          isInvalid={invalidSector}
          validation={sectorValidation}
        />
      </Box>

      {sectorValue?.industries?.length ? (
        <Box mt="1.5rem">
          <FormLabel fontSize="0.875rem" mt=".4rem" as="span">
            {t('startupProfile:label.industry')}
            {isRequired && (
              <Text color="red.500" as="span" lineHeight={'0rem'}>
                {' '}
                *
              </Text>
            )}
          </FormLabel>
          <Select
            onBlur={onSelectBlur}
            selectType="default"
            onChange={handleIndustryChange}
            options={sortOptionsByLabel(getIndustryOptions(getSelectedSector()?.industries || []))}
            name="industry"
            placeholder=""
            type="text"
            value={industryValue}
            noOptionsMessage={() => null}
            loadingMessage={() => t('label.loading')}
            isLoading={!isSectorsLoaded}
            isInvalid={invalidIndustry}
            validation={industryValidation}
          />
        </Box>
      ) : null}
    </Box>
  );
};

SectorAndIndustryFields.propTypes = {
  onSelectBlur: PropTypes.func,
  invalidSector: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  invalidIndustry: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  industryValidation: PropTypes.object,
  sectorValidation: PropTypes.object,
  onChange: PropTypes.func,
  isRequired: PropTypes.bool
};

export default SectorAndIndustryFields;
