import React from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { JOIN, BUSINESS_PROFILE } from '../../../constants/routes.constants';
import LanguageSwitcher from '../LanguageSwitcher';
import { Button } from './Navbar.styles';
import { device } from '../../../constants/userDevice';

const GuestControls = () => {
  const {
    t,
    i18n: { language }
  } = useTranslation('createAccount');
  const { pathname } = useLocation();

  return (
    <Flex direction={'row'} alignItems="center" mt="4px">
      {[BUSINESS_PROFILE, JOIN].some(elm => elm === pathname) ? (
        device !== 'mobile' && (
          <>
            <Text display="inline">{t('label.loggedOut')}</Text>
            <a href={`https://app.tribalcredit.io/login?${language}`}>
              <Button variant="ghost">{t('translation:label.signin')}</Button>
            </a>
          </>
        )
      ) : (
        <></>
      )}
      <LanguageSwitcher />
    </Flex>
  );
};

export default GuestControls;
