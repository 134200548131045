import { space } from './space';

export const sizes = {
  ...space,
  max: 'max-content',
  min: 'min-content',
  full: '100%',
  '3xs': '22.4rem',
  '2xs': '25.6rem',
  xs: '32rem',
  sm: '38.4rem',
  md: '44.8rem',
  lg: '51.2rem',
  xl: '57.6rem',
  '2xl': '67.2rem',
  '3xl': '76.8rem',
  '4xl': '89.6rem',
  '5xl': '102.4rem',
  '6xl': '115.2rem',
  '7xl': '128rem',
  '8xl': '144rem',
  container: {
    sm: '640px',
    md: '768px',
    lg: '1024px',
    xl: '1280px'
  }
};
