import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import TagManager from 'react-gtm-module';
// import smartlookClient from 'smartlook-client';

import * as GoogleAnalytics from '../google';
// import { GTM } from '../index';
import * as gclid from '../gclid';

const useInitAnalytic = () => {
  const {
    REACT_APP_GOOGLE_TAG_MANAGER_ID: gtmId,
    REACT_APP_GOOGLE_ANALYTICS_ID: gaId
    // REACT_APP_SMARTLOOK_KEY: smartlookId
  } = process.env;

  useEffect(() => {
    gtmId && TagManager.initialize({ gtmId });
    gaId && GoogleAnalytics.initialize(gaId);
    // smartlookId && smartlookClient.init(smartlookId);
    gclid.init();
  }, []);
};

const useGooglePageAnalytic = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    GoogleAnalytics.sendVisitedPage(pathname);
  }, [pathname]);
};

interface IPagesVisitAnalyticProps {
  children: React.ReactNode | null;
}

const PagesVisitAnalytic: React.FC<IPagesVisitAnalyticProps> = ({ children }) => {
  useInitAnalytic();
  useGooglePageAnalytic();

  return <>{children}</>;
};

export default PagesVisitAnalytic;
