import { Flex } from '@chakra-ui/react';
import React from 'react';
import Navbar from '../Navbar';
import { Container } from './Layout.styles';

interface ILayout {
  children: React.ReactNode;
  extraStyles?: { [key: string]: string };
}

const Layout: React.FC<ILayout> = ({ children, extraStyles }) => {
  return (
    <Flex
      style={extraStyles}
      direction="column"
      minHeight="100vh"
      padding={{ '2xs': '10px 10px', lg: '20px 48px' }}
    >
      <Navbar />
      <Container>{children}</Container>
    </Flex>
  );
};

export default Layout;
