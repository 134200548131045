import React from 'react';
import { Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const Content = (props: any) => {
  const { t } = useTranslation('rejection');
  return (
    <Text maxW="xs" color="#6B6E74" fontFamily="body" textAlign="center">
      {props.notABusinessRegister
        ? t('content.contentOfRejection1')
        : props.revenueIssue
        ? t('content.contentOfRejection2')
        : ''}
    </Text>
  );
};
