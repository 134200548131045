import { defineStyleConfig, theme } from '@chakra-ui/react';

export default defineStyleConfig({
  baseStyle: {
    ...theme.components.Form,
    helperText: {
      fontFamily: 'PublicSansRegular',
      color: 'neutral.600',
      fontSize: '12px',
      lineHeight: '15px'
    }
  }
});
