import { useEffect, useState } from 'react';
import { getCountries } from '../services/common.service';

export const useGetCountries = () => {
  const [countries, setCountries] = useState([]);
  const [areCountriesLoading, setAreCountriesLoading] = useState(false);

  useEffect(() => {
    setAreCountriesLoading(true);

    getCountries()
      .then(countries =>
        setCountries(
          countries
            .map((country: { code: string; name: string; currencyCode: string }) => ({
              value: country.code,
              label: country.name,
              code: country.code,
              currencyCode: country.currencyCode
            }))
            .sort((a: { label: number }, b: { label: number }) => (a.label < b.label ? -1 : 1))
        )
      )
      .finally(() => setAreCountriesLoading(false));
  }, []);

  return { countries, areCountriesLoading };
};
