import { defineStyleConfig, theme } from '@chakra-ui/react';

export default defineStyleConfig({
  defaultProps: {
    colorScheme: 'dark',
    size: 'small',
    variant: 'primary'
  },
  baseStyle: {
    ...theme.components.Button.defaultProps,
    borderRadius: 'full',
    lineHeight: 'normal',
    fontFamily: 'PublicSansRegular',
    border: '1px solid transparent'
  },
  sizes: {
    small: {
      fontSize: '14px',
      lineHeight: '14px',
      padding: '7px 12px'
    },
    medium: {
      fontSize: '14px',
      lineHeight: '16px',
      padding: '10px 16px'
    },
    large: {
      fontSize: '16px',
      lineHeight: '18px',
      padding: '13px 20px'
    }
  },
  variants: {
    primary: {
      bg: 'dark.900',
      color: 'neutral.50',
      _hover: {
        bg: 'neutral.700',
        _disabled: {
          bg: 'neutral.100'
        }
      },
      _active: {
        bg: 'neutral.600'
      },
      _focus: {
        border: '1px solid',
        borderColor: 'neutral.50',
        boxShadow: 'validField'
      },
      _disabled: {
        bg: 'neutral.100',
        color: 'neutral.300',
        opacity: 1
      }
    },
    neutral: {
      bg: 'transparent',
      color: 'dark.900',
      border: '1px solid',
      borderColor: 'neutral.300',
      _hover: {
        bg: 'neutral.200',
        _disabled: {
          bg: 'neutral.100'
        }
      },
      _active: {
        bg: 'neutral.300'
      },
      _focus: {
        boxShadow: 'validField'
      },
      _disabled: {
        border: 'none',
        color: 'neutral.300',
        bg: 'neutral.100'
      }
    },
    ghost: {
      bg: 'transparent',
      color: 'dark.900',
      _hover: {
        bg: 'neutral.200'
      },
      _active: {
        bg: 'neutral.300'
      },
      _focus: {
        boxShadow: 'validField'
      },
      _disabled: {
        color: 'neutral.300'
      }
    },
    inputIconButton: {
      color: 'neutral.400',
      _focus: { boxShadow: 'none' },
      _active: { boxShadow: 'none' },
      _disabled: { opacity: '4.5' }
    },
    dropdown: {
      color: 'neutral.700',
      fontWeight: '400',
      w: 'full',
      h: 'max',
      minH: '3.7rem',
      borderRadius: 'md',
      _focus: { boxShadow: 'none' },
      _disabled: { opacity: '4.5' }
    },
    pagination: {
      bg: 'transparent',
      color: 'neutral.600',
      borderWidth: '0.1rem',
      borderColor: 'transparent',
      borderRadius: 'md',
      px: '1rem',
      py: '0.8rem',
      h: '3.6rem',
      fontSize: 'sm',
      _hover: {
        color: 'neutral.700',
        bg: 'neutral.200',
        border: '1px solid transparent'
      },
      _active: { color: 'teal.700', bg: 'teal.200' },
      _focus: { boxShadow: 'none' }
    },
    dark: {
      ...theme.components.Button.defaultProps,
      bg: 'black',
      _hover: {
        color: 'white',
        bg: 'teal.600'
      }
    },
    waitlist: {
      bg: 'dark.900',
      color: 'neutral.50',
      _hover: {
        bg: 'neutral.700',
        _disabled: {
          bg: 'teal.400'
        }
      },
      _active: {
        bg: 'neutral.600'
      },
      _focus: {
        border: '1px solid',
        borderColor: 'neutral.50',
        boxShadow: 'validField'
      },
      _disabled: {
        bg: 'teal.400',
        color: 'white',
        opacity: 1
      }
    }
  }
});
