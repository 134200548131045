import facebook from '../assets/images/facebook.svg';
import instagram from '../assets/images/instagram.svg';
import twitter from '../assets/images/twitter.svg';
import linkedin from '../assets/images/linkedin.svg';
import youtube from '../assets/images/youtube.svg';

export const socialMedia = [
  { key: 'instagram', img: instagram, url: 'https://www.instagram.com/tribalhq/' },
  { key: 'facebook', img: facebook, url: 'https://www.facebook.com/TribalHQ/' },
  { key: 'youtube', img: youtube, url: 'https://www.youtube.com/channel/UC4_lNfCFgEZRqzi7KZajOrQ' },
  { key: 'twitter', img: twitter, url: 'https://twitter.com/tribalcredit' },
  { key: 'linkedin', img: linkedin, url: 'https://www.linkedin.com/company/tribal-credit' }
];
