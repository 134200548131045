import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { amplitude } from '../../utils/amplitude';
import useActions from '../../hooks/useActions';
import { selectUser } from '../../redux/UserData/UserData.selectors';
import { JOIN } from '../../constants/routes.constants';
import { socialMedia } from '../../utils/socialMediaLinks';

import { Layout } from '../../components/LayoutComponents';
import { Text, Flex, Image, Link, Center, Box } from '@chakra-ui/react';
import BrandMark from '../../assets/images/tribal-brandmark.png';
import { ShineStyled } from '../../components/Shine/Shine.styles';
import { device } from '../../constants/userDevice';

export const ThankYou = () => {
  const { t } = useTranslation('thankYou');
  const { applicationId } = useSelector(selectUser);
  const hasRouteValidation = applicationId.length > 0;
  const { resetState } = useActions();

  useEffect(() => {
    if (hasRouteValidation) {
      // Amplitude event
      amplitude.track({ name: 'Thank You Page Viewed' });

      // reset CreateAccount persistant state
      resetState();
    }
  }, [hasRouteValidation]);

  // Validate if user hasn't been registered yet,
  // otherwise redirect route
  if (!hasRouteValidation) {
    return <Navigate replace to={JOIN} />;
  }

  return (
    <Layout>
      <Flex margin="auto" direction="column" h="calc(100vh - 184px)" minH="700px" w="100%">
        <Flex direction="column" align="center" justify="center" grow={1}>
          <Flex
            direction="column"
            align="center"
            justify="center"
            sx={{ textAlign: 'center' }}
            grow={1}
            maxW="700px"
          >
            <Center position="relative" width="100%" height="84px" mb="42px">
              <ShineStyled w={'153px'} h={'121px'} position="absolute" top="-90px" />
              <Image src={BrandMark} boxSize="84px" objectFit="contain" />
            </Center>

            <Text
              fontFamily="Public Sans"
              fontWeight="700"
              fontSize={device === 'mobile' ? '2.5rem' : '3.5rem'}
              mb="14px"
              lineHeight={device === 'mobile' ? 'initial' : '78px'}
            >
              {t('header')}
            </Text>
            <Text fontSize="22px" fontWeight="600" mb="30px" lineHeight="31px">
              {t('text1')}
            </Text>

            <Box mb="25px" w="80%">
              <Text
                fontSize="18px"
                fontWeight="400"
                fontFamily="Public Sans"
                lineHeight="25px"
                display="inline"
              >
                {t('text2')}
              </Text>
              <Text
                fontSize="18px"
                fontFamily="Public Sans"
                lineHeight="25px"
                fontWeight="600"
                display="inline"
              >
                {t('text2Bold')}
              </Text>
            </Box>

            <Box>
              <Text
                fontSize="18px"
                fontWeight="400"
                fontFamily="Public Sans"
                lineHeight="25px"
                display="inline"
              >
                {t('text3')}
              </Text>
              <Text
                fontSize="18px"
                fontFamily="Public Sans"
                lineHeight="25px"
                fontWeight="600"
                display="inline"
              >
                {t('text3Bold')}
              </Text>
            </Box>
          </Flex>
          <Flex>
            {socialMedia.map(elm => (
              <Link key={elm.key} href={elm.url} target="_blank">
                <Image src={elm.img} w={'32px'} h={'32px'} mx="7.5px" />
              </Link>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  );
};
