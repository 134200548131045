export const radii = {
  none: '0',
  sm: '0.2rem',
  base: '0.4rem',
  md: '0.6rem',
  lg: '0.8rem',
  xl: '1.2rem',
  '2xl': '1.6rem',
  '3xl': '2.4rem',
  full: '9999px'
};
