import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { selectAccountCreation } from '../../redux/AccountCreation/AccountCreation.selectors';

import { amplitude } from '../../utils/amplitude';
import useActions from '../../hooks/useActions';
import { JOIN } from '../../constants/routes.constants';
import { socialMedia } from '../../utils/socialMediaLinks';

import { Layout } from '../../components/LayoutComponents';
import { Text, Flex, Image, Link, Button } from '@chakra-ui/react';
import BrandMark from '../../assets/images/tribal-brandmark.png';
import { CheckCircle } from '@emotion-icons/feather';
import { device } from '../../constants/userDevice';

export const Waitlist = () => {
  const { t } = useTranslation('waitlist');
  const { resetState } = useActions();
  const { leads, registerForm } = useSelector(selectAccountCreation);
  const [hasClickedBtn, setHasClickedBtn] = useState<boolean>(false);

  const renderRejection = parseInt(localStorage.getItem('renderRejection') || '0');
  const hasRouteValidation = leads && leads?.qualified === false;

  useEffect(() => {
    if (renderRejection && renderRejection > 0) {
      resetState();
    }

    if (hasRouteValidation) {
      localStorage.setItem('renderRejection', String(renderRejection + 1));
      amplitude.track({ name: 'Waitlist Page Viewed' });
    }
  }, [hasRouteValidation]);

  // Validate if user hasn't been registered yet,
  // otherwise redirect route
  if (!hasRouteValidation) {
    localStorage.removeItem('renderRejection');
    return <Navigate replace to={JOIN} />;
  }

  const onClickWaitlistJoinBtn = () => {
    setHasClickedBtn(true);
    amplitude.track({ name: 'Waitlist Join Button Clicked' });
  };

  return (
    <Layout>
      <Flex margin="auto" direction="column" h="calc(100vh - 184px)" minH="700px" w="100%">
        <Flex direction="column" align="center" justify="center" grow={1}>
          <Flex
            direction="column"
            align="center"
            justify="center"
            sx={{ textAlign: 'center' }}
            grow={1}
            maxW="760px"
          >
            <Image src={BrandMark} boxSize="84px" objectFit="contain" mb="37px" />

            <Text
              fontFamily="Public Sans"
              fontWeight="700"
              fontSize={device === 'mobile' ? '2.5rem' : '3.5rem'}
              mb="10px"
              lineHeight="normal"
            >
              {t('title')}
            </Text>

            <Text fontFamily="Public Sans" fontSize="18px" fontWeight="600" lineHeight="normal">
              {t('subtitle')}
            </Text>

            <Text
              fontFamily="Public Sans"
              fontSize="16px"
              fontWeight="400"
              lineHeight="normal"
              mb="27px"
              mt="43px"
            >
              {t('description', {
                name: registerForm.firstName,
                company: registerForm.companyName,
                country: registerForm.countryHq
              })}
            </Text>

            <Text
              fontFamily="Public Sans"
              fontSize="16px"
              fontWeight="600"
              lineHeight="normal"
              mb="7px"
            >
              {t('details')}
            </Text>

            <Text fontFamily="Public Sans" fontSize="16px" fontWeight="400" lineHeight="normal">
              {t('email', { email: registerForm.businessEmail })}
            </Text>

            <Button
              fontFamily="Public Sans"
              fontSize="14 px"
              fontWeight="600"
              px="25px"
              py="12px"
              mt="28px"
              h="44px"
              onClick={onClickWaitlistJoinBtn}
              variant="waitlist"
              disabled={hasClickedBtn}
              {...(hasClickedBtn && {
                leftIcon: <CheckCircle width="18.4px" height="18.4px" strokeWidth={2} />
              })}
            >
              {hasClickedBtn ? t('button.success') : t('button.cta')}
            </Button>
          </Flex>

          <Flex>
            {socialMedia.map(elm => (
              <Link key={elm.key} href={elm.url} target="_blank">
                <Image src={elm.img} w={'32px'} h={'32px'} mx="7.5px" />
              </Link>
            ))}
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  );
};
