import React from 'react';
import { Heading } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

export const HeadLine = () => {
  const { t } = useTranslation('rejection');
  return (
    <Heading mt="48" mb="4" maxW="80" fontFamily="heading" textAlign={'center'}>
      {t('title.header')}
    </Heading>
  );
};
