import { tagAnatomy } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(tagAnatomy.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  container: {
    padding: '10px !important;',
    border: '1px solid #D1D2D4',
    borderRadius: '8px'
  },
  label: {
    fontFamily: 'PublicSansRegular',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '16px'
  },
  closeButton: {
    width: 'auto',
    height: 'auto',
    '>svg': {
      boxSize: '8px',
      width: '16px',
      height: '16px'
    }
  }
});

export default defineMultiStyleConfig({ baseStyle });
