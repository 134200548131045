import React, { useState } from 'react';
import { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import { FormLabel, Text } from '@chakra-ui/react';
import { PROHIBITED_COUNTRIES, TOP_BUSINESS_HQ_COUNTRIES } from '../../constants';
import { useGetCountries } from '../../../../hooks/useGetCountries';
import Select from '../../../../components/CustomSelect';

import colors from '../../../../assets/styles/_colors.scss';
import './CountrySelect.scss';
import { useFormContext } from 'react-hook-form';

const Option = props => {
  const { data: country } = props;

  return (
    <components.Option {...props}>
      <div className="react-select-option">
        <span className={`fi fi-${country.code.toLowerCase()}`} />
        <span className="react-select-option-value">{country.label}</span>
      </div>
    </components.Option>
  );
};

const customComponents = {
  DropdownIndicator: components.DropdownIndicator,
  SingleValue: Option,
  Option
};

const CountrySelect = ({
  name,
  label,
  value,
  onChange,
  onBlur,
  isInvalid,
  validation,
  isRequired
}) => {
  const { t } = useTranslation('createAccount');
  const [fieldTouched, setFieldTouched] = useState(false);
  const methods = useFormContext();
  const { countries, areCountriesLoading } = useGetCountries();
  const customReactSelectStyles = {
    control: (provided, prop) => {
      const isNotValid = (!methods.getValues()[name] && fieldTouched) || !!isInvalid;
      return {
        ...provided,
        display: 'flex',
        padding: '0',
        border: isNotValid ? `2px solid #d73f35` : `1px solid  #D1D2D4`,
        borderColor: isNotValid ? `#d73f35` : `#D1D2D4`,
        boxShadow: '',
        '&:hover': {
          borderColor: isNotValid ? `#d73f35` : `#D1D2D4`
        }
      };
    },
    option: (provided, { isSelected }) => {
      const styles = {
        ...provided,
        width: 'auto',
        padding: '8px 8px',
        backgroundColor: 'transparent',

        '.react-select-option': {
          color: '#000'
        },

        '&:hover': {
          backgroundColor: '#D1D2D4',
          cursor: 'pointer'
        },

        '&:active': {
          backgroundColor: '#D1D2D4 !important'
        }
      };

      if (isSelected) {
        styles.backgroundColor = '#256674 !important';
        styles['.react-select-option'] = {
          color: 'hsl(0,0%,100%) !important'
        };
      }

      return styles;
    },
    group: provided => ({
      ...provided,
      '&:first-of-type': {
        borderBottom: `1px solid ${colors.bg500} !important`
      },
      '&:only-child': {
        padding: 0,
        borderBottom: `none !important`
      },
      '> div:first-of-type': {
        display: 'none'
      }
    }),
    valueContainer: (provided, prop) => {
      return {
        ...provided,
        display: 'flex'
      };
    }
  };
  const preparedCountries = countries
    .filter(country => !PROHIBITED_COUNTRIES.includes(country.code))
    .reduce(
      (acc, country) => {
        TOP_BUSINESS_HQ_COUNTRIES.includes(country.code)
          ? acc[0].options.push(country)
          : acc[1].options.push(country);
        return acc;
      },
      [
        { label: null, options: [] },
        { label: null, options: [] }
      ]
    );
  const handleOnChange = (value, name) => {
    onChange(value, name);
    setFieldTouched(true);
  };
  const handleOnBlur = () => {
    onBlur(name);
    setFieldTouched(true);
  };
  return (
    <div className="country-select">
      <FormLabel fontSize="0.875rem" mt=".4rem" as="span">
        {label}
        {isRequired && (
          <Text color="red.500" as="span" lineHeight={'0rem'}>
            {' '}
            *
          </Text>
        )}
      </FormLabel>
      <Select
        onBlur={handleOnBlur}
        isClearable={true}
        selectType="default"
        onChange={value => handleOnChange(value, name)}
        options={preparedCountries}
        name={name}
        placeholder=""
        type="text"
        // value={value}
        components={customComponents}
        styles={customReactSelectStyles}
        isLoading={areCountriesLoading}
        loadingMessage={() => t('common.loading')}
        isInvalid={isInvalid}
        validation={validation}
      />
    </div>
  );
};

export default CountrySelect;
