import React from 'react';
import { Text, Flex, Progress, Image } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import Refresh from '../../../../assets/images/refresh.svg';

interface ISatLoader {
  timeout: boolean;
  setTimeoutError: (state: boolean) => void;
  setLoader: (state: boolean) => void;
}

export const SatLoader = ({ timeout, setTimeoutError, setLoader }: ISatLoader) => {
  const { t } = useTranslation('satConnect');

  return (
    <Flex
      color="#373C46"
      align="center"
      justify="center"
      direction="column"
      textAlign="center"
      gap="1rem"
    >
      <Text fontWeight="600" fontSize="18px">
        {t('loading.title')}
      </Text>
      <Progress
        colorScheme="teal"
        height="1.2rem"
        w="130%"
        maxW="90vw"
        isIndeterminate
        sx={{
          borderRadius: 'full',
          bg: 'teal.200',
          div: {
            bgImage: 'linear-gradient(to right, transparent 0%, #309597 50%, transparent 100%)'
          }
        }}
      />
      <Text fontFamily="PublicSansRegular" fontSize="14px">
        {timeout ? t('loading.timeoutDescription') : t('loading.description')}
      </Text>
      {timeout && (
        <Flex cursor="pointer" onClick={() => (setTimeoutError(false), setLoader(false))}>
          <Image src={Refresh} h="100%" />
          <Text fontSize="14px" lineHeight="14px" ml="0.5rem" color="teal.400">
            {t('loading.refresh')}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};
