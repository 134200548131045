import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  dialog: {
    borderRadius: '14px'
  },
  header: {
    fontWeight: 600,
    fontSize: '22px',
    lineHeight: '31px',
    paddingTop: '44px',
    paddingBottom: '1.2rem'
  },
  closeButton: {
    margin: '0.8rem 1rem',
    width: 'auto',
    height: 'auto',
    '>svg': {
      width: '11px',
      height: '11px'
    },
    _focusVisible: {
      boxShadow: 'transparent'
    }
  },
  footer: {
    paddingTop: '3rem'
  }
});

export default defineMultiStyleConfig({
  baseStyle
});
