import { axiosInstance } from './prospects.instance';
import { headerWithSavedToken } from '.';
import { ICreateProfile } from '../types/Interface';

// If experiment is enabled send saved token, else send user token
export const createProfile = async ({
  data,
  withoutUserToken
}: {
  data: ICreateProfile;
  withoutUserToken: boolean;
}) => {
  return await axiosInstance({
    ...{ ...(withoutUserToken && { headers: headerWithSavedToken() }) },
    method: 'post',
    url: `/v1/applications`,
    data
  })
    .then(response => response)
    .catch(({ response }) => response.data);
};
export const submitApplication = async ({
  id,
  withoutUserToken
}: {
  id: string;
  withoutUserToken: boolean;
}) => {
  return await axiosInstance({
    ...{ ...(withoutUserToken && { headers: headerWithSavedToken() }) },
    method: 'post',
    url: `/v1/applications/${id}/submit`,
    data: {}
  })
    .then(response => response)
    .catch(({ response }) => response.data);
};
export const createApplicationParticipants = async ({
  id,
  data,
  withoutUserToken
}: {
  id: string;
  data: {
    name: string;
    lastName: string;
    email: string;
    type: string;
    phone: number;
    areaCode: number;
    title: string;
  };
  withoutUserToken: boolean;
}) => {
  return await axiosInstance({
    ...{ ...(withoutUserToken && { headers: headerWithSavedToken() }) },
    method: 'post',
    url: `/v1/applications/${id}/participants`,
    data
  })
    .then(response => response)
    .catch(({ response }) => response.data);
};
