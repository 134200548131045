import React, { useEffect, useMemo, useState } from 'react';
import Select, { components } from 'react-select';
import { useTranslation } from 'react-i18next';
import { FormLabel, Box, Icon, FormControl, FormErrorMessage, Text } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import {
  getDefaultCountryCode,
  getFilteredPhoneCodes,
  isBackspace,
  isLetter,
  isNumber
} from '../../utils';
import { ReactComponent as AlertIcon } from '../../../../assets/images/alert.svg';
import Input from '../../../../components/Input';
import './PhoneSelect.scss';
import { useFormContext } from 'react-hook-form';

const useFilteredOptions = () => {
  const [filter, setFilter] = useState('');

  const filteredOptions = useMemo(() => getFilteredPhoneCodes(filter), [filter]);

  return { setFilter, filteredOptions };
};

const Option = props => {
  const { data: country } = props;

  return (
    <components.Option {...props}>
      <div className="react-select-option">
        <span className="react-select-option-label">
          <span className={`fi fi-${country.countryCode.toLowerCase()}`} /> {country.value}{' '}
        </span>
      </div>
    </components.Option>
  );
};

const reactSelectComponents = {
  IndicatorSeparator: null,
  Option
};

const customReactSelectStyles = {
  container: provided => ({
    ...provided,
    flex: 1,

    position: 'static'
  }),
  control: (provided, { selectProps: { isValid, isTouched } }) => {
    const border = `${isValid && isTouched ? '1px' : '2px'}  solid ${
      !isValid && isTouched ? '#D73F35' : '#D1D2D4'
    }`;

    return {
      display: 'flex',
      height: 40,
      backgroundColor: '#f8f8f8',
      border,
      borderRadius: 0,
      borderTopLeftRadius: '5px',
      borderBottomLeftRadius: '5px',
      borderRight: 0,
      outline: 'none',
      width: '98px',
      fontSize: 17,
      fontFamily: 'PublicSansRegular'
    };
  },
  group: provided => ({
    ...provided,
    padding: '10px 0'
  }),
  indicatorsContainer: provided => ({
    ...provided,
    color: '#c4c4c4',
    '> div:first-of-type': {
      paddingLeft: 0,
      paddingRight: 4
    },
    '&:hover': {
      cursor: 'pointer'
    }
  }),
  valueContainer: (provided, { selectProps: { isValid, isTouched } }) => ({
    ...provided,
    padding: `2px 0px 2px ${isValid && isTouched ? '8px' : '7px'}`
  }),
  menu: provided => ({
    ...provided,
    minWidth: 320,
    width: '65%',
    margin: '0',
    zIndex: '1000',
    top: '125%'
  }),
  menuList: provided => ({
    ...provided,
    padding: '0 10px',

    '> div:first-of-type': {
      borderBottom: `1px solid #848484`
    }
  }),
  option: (provided, { isFocused, isSelected }) => {
    const backgroundColor = (() => {
      if (isSelected) return '#256674';
      if (isFocused && !isSelected) return '#D1D2D4';
      return 'none';
    })();
    const styles = {
      ...provided,
      padding: '8px 8px',
      ':active': {
        backgroundColor: 'none'
      },
      '&:hover': {
        cursor: 'pointer'
      },
      backgroundColor
    };

    return styles;
  },
  singleValue: provided => ({
    ...provided,
    color: '#373c46',
    margin: 0,
    width: '100%'
  })
};

const PhoneSelect = ({ values, onSelect, isInvalid, validation, isRequired }) => {
  const {
    t,
    i18n: { language }
  } = useTranslation('createAccount');
  const [isChangedByBusinessHQ, setIsChangedByBusinessHQ] = useState();
  const [isChangedByUserInput, setIsChangedByUserInput] = useState(false);
  const { filteredOptions, setFilter } = useFilteredOptions();
  const methods = useFormContext();
  useEffect(() => {
    !values.countryCode &&
      !isChangedByBusinessHQ &&
      !isChangedByUserInput &&
      onSelect(getDefaultCountryCode(), 'countryCode');
  }, [language]);

  const defaultCountryCode = getDefaultCountryCode();

  const handleChangePhoneCode = ({ label, value, code }) => {
    onSelect({ label, value, code }, 'phoneCode');
    !isChangedByUserInput && setIsChangedByUserInput(true);
  };

  const handleKeyDown = e => {
    e.persist();

    if (isBackspace(e)) {
      setFilter(prevState => prevState.substring(0, prevState.length - 1));
      return;
    }
    if (isLetter(e) || isNumber(e)) {
      setFilter(prevState => prevState + e.key);
    }
  };

  const phoneCustomValidation = () => ({
    ...validation,
    maxLength: {
      value: 16 - values?.phoneCode?.code.length,
      message: 'errorMsgs:errors.maxLength15'
    }
  });

  return (
    <Box>
      <FormControl mb="0" isInvalid={!!isInvalid}>
        <FormLabel fontSize="0.875rem" mt=".4rem" as="span">
          {t('label.phoneNumber')}
          {isRequired && (
            <Text color="red.500" as="span" lineHeight={'0rem'}>
              {' '}
              *
            </Text>
          )}
        </FormLabel>
        <div className="phone-select__phone">
          <Select
            name="phoneCode"
            stateName="phoneCode"
            isSearchable={false}
            options={filteredOptions}
            components={reactSelectComponents}
            styles={customReactSelectStyles}
            value={values.phoneCode}
            defaultValue={defaultCountryCode}
            isValid={!isInvalid}
            isTouched={true}
            onChange={handleChangePhoneCode}
            onKeyDown={handleKeyDown}
            onMenuClose={() => setFilter('')}
          />

          <Input
            mt=".0rem"
            name="phoneNumber"
            type="number"
            stateName="phoneNumber"
            showError={false}
            borderTopLeftRadius="0rem"
            borderBottomLeftRadius="0rem"
            validation={phoneCustomValidation()}
            isInvalid={isInvalid}
          />
        </div>
        <FormErrorMessage fontSize=".9rem" textAlign="start">
          <Icon fontSize="1rem" mr=".2rem" mb=".3rem" as={AlertIcon} /> {t(isInvalid?.message)}
        </FormErrorMessage>
      </FormControl>
    </Box>
  );
};

PhoneSelect.propTypes = {
  values: PropTypes.object,
  onSelect: PropTypes.func,
  isInvalid: PropTypes.object,
  validation: PropTypes.object,
  isRequired: PropTypes.bool
};

Option.propTypes = {
  data: PropTypes.shape({
    countryCode: PropTypes.string,
    value: PropTypes.string
  }).isRequired
};

export default PhoneSelect;
