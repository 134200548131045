import React, { useState } from 'react';
import { Layout } from '../../components/LayoutComponents';
import { useTranslation, Trans } from 'react-i18next';
import { Text, Flex, Image, Center, Icon } from '@chakra-ui/react';
import SatLogo from '../../assets/images/logo-sat.png';
import Brandmark from '../../assets/images/logo.svg';
import ShortBrandmark from '../../assets/images/tribal-brandmark.png';
import { CheckCircle, Repeat } from '@emotion-icons/feather';
import { SatForm } from './components/SatForm/SatForm';

export default function SatConnection() {
  const { t } = useTranslation('satConnect');

  const [timeoutError, setTimeoutError] = useState<boolean>(false);
  const [satConnected, setSatConnected] = useState<boolean>(false);

  const SatConnectedSuccessfully = () => (
    <Flex textAlign="center" flexDirection="column" align="center" w="35%" minW="350px">
      <Text fontSize="1.8rem" lineHeight="42px" color="#262C36">
        {t('satConnectedSuccess.title')}
      </Text>
      <Text fontFamily="PublicSansRegular" fontSize="14px" mt="1rem" color="#373C46">
        <Trans t={t} i18nKey={t('satConnectedSuccess.description')} />
      </Text>
    </Flex>
  );

  return (
    <Layout extraStyles={{ backgroundColor: '#F9F9F9' }}>
      <Flex direction="column" h="calc(100vh - 184px)" w="100%">
        <Flex direction="column" align="center" justify="center" grow={1}>
          {!satConnected && (
            <Text fontSize="2rem" textAlign="center" fontWeight="600" lineHeight="30px">
              {t('title')}
            </Text>
          )}

          <Center gap="1.2rem" py="1.5rem" h="5.6rem">
            <Image src={SatLogo} h="100%" />
            {satConnected ? (
              <Icon as={CheckCircle} color="teal.400" w="1.4rem" h="1.4rem" strokeWidth="0.1rem" />
            ) : (
              <Icon as={Repeat} w="1.2rem" h="1.2rem" strokeWidth="0.12rem" />
            )}
            <Image
              src={satConnected ? ShortBrandmark : Brandmark}
              h={satConnected ? '100%' : '65%'}
            />
          </Center>
          {satConnected ? (
            <SatConnectedSuccessfully />
          ) : (
            <SatForm
              setSatConnected={setSatConnected}
              setTimeoutError={setTimeoutError}
              timeoutError={timeoutError}
            />
          )}
        </Flex>
      </Flex>
    </Layout>
  );
}
