import { useEffect, useState } from 'react';
import './index.css';
const SITE_ACCESS_ID = process.env.REACT_APP_CLICKWRAP_ACCESS_ID; // A PactSafe Site Access ID
const TEST_MODE = process.env.REACT_APP_CLICKWRAP_TEST_MODE === 'true';
const CONTAINER_ID = 'click-wrap-container';

const useIsClickWrapAvailable = () => {
  const [isClickWrapLoaded, setIsClickWrapLoaded] = useState(false);

  useEffect(() => {
    if (window._ps && window._ps.loaded) {
      setIsClickWrapLoaded(true);
    }
  }, []);

  window._ps?.on('ready', () => setIsClickWrapLoaded(true));

  return isClickWrapLoaded;
};

const useSiteInit = isClickWrapAvailable => {
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    if (!isClickWrapAvailable) return;

    if (TEST_MODE) console.log('TEST_MODE', TEST_MODE); // eslint-disable-line no-console

    window._ps('create', SITE_ACCESS_ID, {
      test_mode: TEST_MODE,
      disable_sending: true,
      dynamic: true
    });
    setIsInitialized(true);
  }, [isClickWrapAvailable]);

  return isInitialized;
};

const useGroupRender = (groupKey, isRenderAvailable) => {
  const getGroupByKey = key => window._ps.getAllGroups().find(group => group.get('key') === key);

  const loadGroup = groupKey =>
    window._ps('load', groupKey, {
      signer_id_selector: 'user-email',
      container_selector: CONTAINER_ID,
      style: 'combined',
      allow_disagreed: true
    });

  useEffect(() => {
    if (!isRenderAvailable) return;

    const group = getGroupByKey(groupKey);

    if (!group) {
      loadGroup(groupKey);
      return;
    }

    group.render(true);
  }, [groupKey, isRenderAvailable]);
};

const ClickWrap = ({ groupKey, onChange, className }) => {
  const isClickWrapAvailable = useIsClickWrapAvailable();
  const isSiteInitialized = useSiteInit(isClickWrapAvailable);
  useGroupRender(groupKey, isSiteInitialized && isClickWrapAvailable);

  window._ps?.on('rendered', () => {
    const checkbox = document.querySelector(`#${CONTAINER_ID} input`);
    checkbox.onchange = event => onChange(event.target.checked);
  });

  return <div id={CONTAINER_ID} className={className}></div>;
};

export default ClickWrap;
