import React from 'react';
import { Spinner as Loading } from '@chakra-ui/react';
import * as S from './Spinner.styles';

interface ISpinner {
  open: boolean;
}
const Spinner: React.FC<ISpinner> = ({ open }) => {
  return (
    <>
      {open ? (
        <S.Block>
          <S.Overlay></S.Overlay>
          <Loading thickness="3px" speed="0.65s" emptyColor="gray.200" color="blue.800" size="xl" />
        </S.Block>
      ) : (
        <></>
      )}
    </>
  );
};

export default Spinner;
