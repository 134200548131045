import { defineStyleConfig } from '@chakra-ui/react';

export default defineStyleConfig({
  defaultProps: {
    variant: 'bold',
    size: 'lg'
  },
  sizes: {
    lg: {
      fontSize: '16px',
      lineHeight: '22px'
    },
    md: {
      fontSize: '14px',
      lineHeight: '20px'
    },
    sm: {
      fontSize: '12px',
      lineHeight: '12px'
    },
    xs: {
      fontSize: '10px',
      lineHeight: '14px'
    }
  },
  variants: {
    bold: {
      fontFamily: 'PublicSansSemiBold'
    },
    regular: {
      fontFamily: 'PublicSansRegular'
    }
  }
});
