import React from 'react';
import { Box, Icon } from '@chakra-ui/react';
import { ReactComponent as WarningIcon } from '../../assets/images/WarningIcon.svg';
import { ErrorBox } from '../../types/Interface';

const BulkErrorBox = ({ error }: ErrorBox) => {
  return (
    <Box
      display="flex"
      p="1rem"
      mt="2rem"
      height="3.24rem"
      background="red.50"
      borderRadius="6"
      border="1px solid"
      borderColor="red.300"
      color="red.700"
      fontSize=".8rem"
    >
      <Icon fontSize="1.1rem" mr=".65rem" as={WarningIcon} />
      {error}
    </Box>
  );
};

export default BulkErrorBox;
