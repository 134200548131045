import styled from '@emotion/styled';

import { Button as Btn } from '@chakra-ui/react';

export const Button = styled(Btn)`
  background-color: transparent;
  padding: 14px 20px;
  border: 1px solid var(--chakra-colors-neutral-400);
  border-radius: 100px;
  outline: none;
  cursor: pointer;
  font-family: 'PublicSansBold';
  font-size: 16px;
  margin-left: 13px;
`;
