import React from 'react';
import { Flex, Img } from '@chakra-ui/react';
import GuestControls from './GuestControls';

const Navbar = () => {
  return (
    <Flex
      direction={'row'}
      justifyContent="space-between"
      padding={{ '2xs': '0px 10px', lg: '0px 40px' }}
    >
      <Logo />
      <Flex>
        <GuestControls />
      </Flex>
    </Flex>
  );
};

export default Navbar;

const Logo = () => {
  const handleClick = () => {
    window.open('https://tribal.credit', '_blank');
  };

  return (
    <Img
      alt="tribal"
      src={process.env.PUBLIC_URL + '/logo.png'}
      onClick={handleClick}
      width="133px"
      height={'30px'}
      cursor="pointer"
      mt="13px"
    />
  );
};
