import React, { ChangeEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Flex, FormLabel, Text, Grid, GridItem } from '@chakra-ui/react';
import Select from '../../../../components/CustomSelect';
import CountrySelect from '../CountrySelect';
import PhoneSelect from '../PhoneSelect';
import Input from '../../../../components/Input';
import {
  getRegistrationQuestionOptions,
  getRegistrationQuestionTitle
} from '../../utils/registeredBusiness';
import { getMonthlySalesOptions } from '../../../../utils/monthlySales';
import { getDefaultCountryCode } from '../../utils';
import { CREATE_ACCOUNT } from '../../../../constants/locales';
import { useFlags } from 'launchdarkly-react-client-sdk';
import SectorAndIndustryFields from '../../../../components/SectorAndIndustryFields';
import { ISalesRange, Obj, RegistrationForm } from '../../../../types/Interface';
import { FormProvider, useForm } from 'react-hook-form';
import { fieldsValidation } from '../../../../constants/validation';
import BulkErrorBox from '../../../../components/BulkErrorBox';
import useActions from '../../../../hooks/useActions';
import { selectAccountCreation } from '../../../../redux/AccountCreation/AccountCreation.selectors';
import { useSelector } from 'react-redux';
import { ACCESS_ID, getGroupKey, initClickwrap } from '../../../../utils/clickwrap';
import ClickWrap from '../ClickWrap';

type Org = { [key: string]: string };
interface submitProps {
  onSubmit: (values: RegistrationForm) => void | any;
}
const Form = ({ onSubmit }: submitProps) => {
  const [hasAgreed, setHasAgreed] = useState<boolean>(false);
  const [salesRanges, setSalesRanges] = useState<ISalesRange[]>([]);
  const [formValues, setFormValues] = useState<RegistrationForm>({
    businessHQ: null,
    companyName: '',
    firstName: '',
    lastName: '',
    email: '',
    isRegisteredCompany: null,
    monthlySales: null,
    referralCode: '',
    sectors: '',
    industry: '',
    phoneNumber: '',
    phoneCode: null,
    countryCode: null
  });

  const methods = useForm<RegistrationForm>();
  const { pocRegistrationPage, experiment } = useFlags();

  const { t } = useTranslation(CREATE_ACCOUNT);
  const [isFormSubmitted, setIsFormSubmitted] = useState<boolean>();

  //Redux state
  const { setSalesRange, setGroupKey } = useActions();
  const { salesRange } = useSelector(selectAccountCreation);

  // Needed to get option for current language
  const getSelectedOption = (options: any, selectedValue: any) => {
    return options?.find((option: any) => selectedValue === option.value) || null;
  };

  // Clickwrap
  const country = methods.watch('businessHQ')?.code;
  const groupKey = useMemo(() => country && getGroupKey(country), [country]);
  const signerId = methods.watch('email');

  const onBlur = (stateName: keyof RegistrationForm) => {
    methods.trigger(stateName);
  };

  const onChange = async (value: string, stateName: keyof RegistrationForm) => {
    await methods.trigger(stateName);
    if (
      !methods.formState.errors[stateName] ||
      value === '' ||
      stateName === 'email' ||
      stateName === 'phoneNumber'
    ) {
      setFormValues({
        ...formValues,
        [stateName]: stateName === 'referralCode' ? value.toUpperCase() : value
      });
    }
  };
  const onSelect = async (value: Obj, stateName: keyof RegistrationForm) => {
    await methods.trigger(stateName);

    let formValuesClone = { ...formValues };
    formValuesClone = { ...formValuesClone, [stateName]: value };

    if (stateName === 'businessHQ') {
      if (formValues.businessHQ?.code !== value?.code) {
        methods.setValue('monthlySales', null);
        formValuesClone = { ...formValuesClone, monthlySales: null };
        formValuesClone.countryCode = getDefaultCountryCode(value?.code);
        methods.setValue('countryCode', getDefaultCountryCode(value?.code));
      }
      setFormValues(formValuesClone);
      if (value) {
        setSalesRange({ countryCode: value?.code });
      }
    }
    if (formValues.phoneCode === null) {
      formValuesClone.phoneCode = getDefaultCountryCode(value?.code);
    }
    setFormValues(formValuesClone);
  };

  const onSelectBlur = (stateName: keyof RegistrationForm) => {
    methods.trigger(stateName);
  };

  const prepareInputValidation = (stateName: keyof RegistrationForm, validation: any) => {
    return {
      ...validation,
      onChange: (e: ChangeEvent<HTMLInputElement>) => onChange(e.target.value, stateName),
      onBlur: () => onBlur(stateName)
    };
  };

  const prepareSelectValidation = (stateName: keyof RegistrationForm, validation: any) => {
    return {
      ...validation,
      onChange: (value: Org) => onSelect(value, stateName),
      onBlur: () => onBlur(stateName)
    };
  };

  const getValues = async () => {
    let values = { ...formValues, ...methods.getValues() };
    let res = await onSubmit(values);
    if (res && Object.keys(res).length) {
      setIsFormSubmitted(false);
      Object.keys(res).forEach((errorKey: any) => {
        methods.setError(errorKey, { type: 'custom', message: res[errorKey] });
      });
    }
  };

  useEffect(() => {
    setSalesRanges(salesRange);
  }, [salesRange]);

  useEffect(() => {
    if (ACCESS_ID) initClickwrap(ACCESS_ID);
  }, [ACCESS_ID, window]);

  useEffect(() => {
    if (groupKey) {
      // Reset agreement checkbox because contract changes by country
      setHasAgreed(false);

      setGroupKey({ groupKey });
    }
  }, [groupKey]);

  useEffect(() => {
    // Reset agreement checkbox if email is not valid
    if (methods.formState.errors.email?.message) setHasAgreed(false);
  }, [signerId]);

  const getCheckedValue = (isChecked: boolean): void => {
    setHasAgreed(isChecked);
  };

  return (
    <Box>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(getValues)}>
          <Grid templateColumns="repeat(auto-fit, minmax(300px, 1fr))" columnGap="1rem">
            <GridItem>
              <Input
                label={t('label.firstName')}
                stateName="firstName"
                isInvalid={methods.formState.errors.firstName}
                onBlur={onBlur}
                validation={prepareInputValidation('firstName', fieldsValidation.firstName)}
                isRequired={true}
              />
            </GridItem>
            <GridItem>
              <Input
                label={t('label.lastName')}
                stateName="lastName"
                isInvalid={methods.formState.errors.lastName}
                onBlur={onBlur}
                validation={prepareInputValidation('lastName', fieldsValidation.lastName)}
                isRequired={true}
              />
            </GridItem>
          </Grid>

          <Input
            {...{ id: 'user-email' }}
            label={t('label.businessEmail')}
            type="email"
            stateName="email"
            isInvalid={methods.formState.errors.email}
            helper={t('description.businessEmail')}
            validation={prepareInputValidation('email', fieldsValidation.email)}
            isRequired={true}
          />

          <Box mt="1.5rem">
            <PhoneSelect
              values={formValues}
              onSelect={onSelect}
              isInvalid={methods.formState.errors.phoneNumber}
              validation={prepareInputValidation('phoneNumber', fieldsValidation.phoneNumber)}
              isRequired={true}
            />
          </Box>

          <Input
            label={t('label.startupName')}
            type="text"
            stateName="companyName"
            isInvalid={methods.formState.errors.companyName}
            validation={prepareInputValidation('companyName', fieldsValidation.companyName)}
            isRequired={true}
          />

          <Box mt="1.5rem">
            <CountrySelect
              name="businessHQ"
              onChange={onSelect}
              value={formValues.businessHQ}
              label={t('label.businessHQ')}
              onBlur={onSelectBlur}
              isInvalid={methods.formState.errors.businessHQ}
              validation={prepareSelectValidation('businessHQ', fieldsValidation.businessHQ)}
              isRequired={true}
            />
          </Box>

          <Box mt="1.5rem">
            <FormLabel fontSize="0.875rem" mt=".4rem" as="span">
              {getRegistrationQuestionTitle(formValues.businessHQ?.code)}
              <Text color="red.500" as="span" lineHeight={'0rem'}>
                *
              </Text>
            </FormLabel>
            <Select
              onBlur={onSelectBlur}
              onChange={(value: Org) => onSelect(value, 'isRegisteredCompany')}
              value={getSelectedOption(
                getRegistrationQuestionOptions(formValues.businessHQ?.code),
                formValues.isRegisteredCompany?.value
              )}
              options={getRegistrationQuestionOptions(formValues.businessHQ?.code)}
              showDefaultDropdownIndicator
              isSearchable={false}
              name="isRegisteredCompany"
              selectType="default"
              placeholder=""
              isInvalid={methods.formState.errors.isRegisteredCompany}
              validation={prepareSelectValidation(
                'isRegisteredCompany',
                fieldsValidation.isRegisteredCompany
              )}
            />
          </Box>
          {/* adding monthly sales select for MENA countries */}
          {
            <Box mt="1.5rem">
              <FormLabel fontSize="0.875rem" mt=".4rem" as="span">
                {t('title.monthlySales')}
                <Text color="red.500" as="span" lineHeight={'0rem'}>
                  *
                </Text>
              </FormLabel>
              <Select
                onBlur={onSelectBlur}
                onChange={(value: Org) => onSelect(value, 'monthlySales')}
                value={getSelectedOption(
                  getMonthlySalesOptions(formValues.businessHQ?.code, salesRanges),
                  formValues.monthlySales?.value
                )}
                name="monthlySales"
                options={getMonthlySalesOptions(formValues.businessHQ?.code, salesRanges)}
                showDefaultDropdownIndicator
                isSearchable={false}
                selectType="default"
                placeholder=""
                isInvalid={methods.formState.errors.monthlySales}
                validation={prepareSelectValidation('monthlySales', fieldsValidation.monthlySales)}
              />
            </Box>
          }

          {pocRegistrationPage && (
            <SectorAndIndustryFields
              onSelectBlur={onSelectBlur}
              onChange={onSelect}
              invalidIndustry={methods.formState.errors.industry}
              invalidSector={methods.formState.errors.sectors}
              industryValidation={prepareSelectValidation('industry', fieldsValidation.industry)}
              sectorValidation={prepareSelectValidation('sectors', fieldsValidation.sectors)}
              isRequired={true}
            />
          )}
          {/* Hidden referral code */}
          {/* <Input
            label={t('label.referralCode')}
            stateName="referralCode"
            onChange={onChange}
            isInvalid={methods.formState.errors.referralCode}
            validation={prepareInputValidation('referralCode', fieldsValidation.referralCode)}
          /> */}

          {isFormSubmitted && Object.keys(methods.formState.errors).length ? (
            <BulkErrorBox error={t('translation:error.formValidation')} />
          ) : null}

          {/* Clickwrap  */}
          {groupKey && signerId && experiment && !methods.formState.errors.email?.message && (
            <Box
              mt="35px"
              sx={{
                '.ps-checkbox-container': {
                  textAlign: 'center'
                },
                '.ps-contract-label a': {
                  fontFamily: 'Public Sans',
                  color: '#373C46',
                  fontWeight: 'bold'
                }
              }}
            >
              <ClickWrap
                className="create-account-form__click-wrap"
                onChange={getCheckedValue}
                groupKey={groupKey}
              />
            </Box>
          )}

          {experiment && (
            <Flex align="center" justify="center">
              <Button
                fontSize="1rem"
                isLoading={methods.formState.isSubmitting}
                loadingText={t('createAccount:common.loading')}
                disabled={experiment ? !hasAgreed : false}
                type="submit"
                variant="primary"
                onClick={() => setIsFormSubmitted(true)}
                fontFamily="Public Sans"
                fontWeight={600}
                w="468px"
                py="15px"
                mt="35px"
                textAlign="center"
              >
                {t('cta')}
              </Button>
            </Flex>
          )}
        </form>
      </FormProvider>
    </Box>
  );
};

const CreateAccountForm = (props: submitProps) => {
  return <Form onSubmit={props.onSubmit} />;
};

export default CreateAccountForm;
