import { createMultiStyleConfigHelpers, defineStyle } from '@chakra-ui/react';
import { checkboxAnatomy } from '@chakra-ui/anatomy';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(
  checkboxAnatomy.keys
);

const baseStyle = definePartsStyle({
  control: {
    _hover: {
      borderColor: 'primary.400',
      bg: 'primary.50'
    },
    _active: {
      borderColor: 'primary.400',
      bg: 'primary.50',
      boxShadow: 'rgba(48,149,151,0.28)'
    },
    _disabled: {
      bg: 'dark.200',
      boderColor: 'dark.300',
      _hover: {
        borderColor: 'dark.100',
        bg: 'dark.200'
      }
    },
    _checked: {
      bg: 'primary.400',
      borderColor: 'primary.400',
      _hover: {
        bg: 'primary.400',
        borderColor: 'primary.400',
        boxShadow: 'rgba(48,149,151,0.28)'
      }
    }
  },
  icon: {
    color: 'neutral.50',
    bg: 'primary.400'
  }
});

const sizes = {
  sm: definePartsStyle({
    container: defineStyle({}),
    control: defineStyle({
      boxSize: '16px'
    }),
    label: defineStyle({
      fontSize: '14px',
      height: '16px'
    })
  })
};

export default defineMultiStyleConfig({ baseStyle, sizes });
