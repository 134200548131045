import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

export const languages: string[] = ['en', 'es', 'pt'];

i18n
  .use(LanguageDetector)
  .use(Backend)
  .use(initReactI18next)
  .init({
    supportedLngs: languages,
    fallbackLng: languages,
    detection: {
      lookupQuerystring: 'lang',
      order: ['cookie', 'localStorage', 'querystring', 'navigator']
    },
    defaultNS: 'translation',
    ns: [
      'translation',
      'completeRegistration',
      'businessProfile',
      'confirmAccount',
      'createAccount',
      'formModal',
      'navHeader',
      'operations',
      'signIn',
      'startupProfile',
      'submitApplication',
      'rejection',
      'errors',
      'errorMsgs',
      'thankYou'
    ],
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
