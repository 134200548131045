import React, { useCallback, useState } from 'react';
import Spinner from '../../components/Spinner';
import { SpinnerContext } from '../../context/Spinner';

interface ISpinnerProvider {
  children: React.ReactNode;
}

const SpinnerProvider: React.FC<ISpinnerProvider> = ({ children }) => {
  const [spinner, setSpinner] = useState(false);

  const open = useCallback(() => {
    setSpinner(true);
  }, []);

  const close = useCallback(() => {
    setSpinner(false);
  }, []);

  return (
    <SpinnerContext.Provider value={{ open, close }}>
      {children}
      <Spinner open={spinner} />
    </SpinnerContext.Provider>
  );
};

export default SpinnerProvider;
