import React, { ReactElement } from 'react';
import { Navigate } from 'react-router-dom';

type IProtectedRoute = {
  name: string;
  redirect?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  children: ReactElement<any, any> | null;
  condition?: boolean;
};

const ProtectedRoute: React.FC<IProtectedRoute> = ({ redirect, children, condition }) => {
  if (redirect && condition) {
    return <Navigate to={redirect} />;
  }

  return children;
};

export default ProtectedRoute;
