import { CancelTokenStatic } from 'axios';
import { axiosProspectInstance, customHeaders } from '.';

export async function getCountries() {
  const url = '/apollo/v1/countries';
  const headers = customHeaders;
  return axiosProspectInstance({
    url,
    method: 'GET',
    headers
  })
    .then(response => response.data)
    .catch(({ response }) => response.data);
}

export async function getStates(countryCode: string) {
  let headers = customHeaders;
  const url = `/apollo/v1/states/${countryCode}`;
  return axiosProspectInstance({
    url,
    method: 'GET',
    headers
  })
    .then(response => response.data)
    .catch(({ response }) => response.data);
}

export async function getCities(countryId: string, stateId: string, cancelToken: any) {
  let headers = customHeaders;
  const url = `/city/get-cities`;
  return axiosProspectInstance({
    url,
    method: 'GET',
    headers,
    params: {
      country_id: countryId,
      state_id: stateId
    },
    cancelToken
  })
    .then(response => response.data)
    .catch(({ response }) => response.data);
}

export async function getSectorsAndIndustries(language: string) {
  let headers = customHeaders;
  const url = `/apollo/v1/sectors/industries?language=${language ?? 'EN'}`;
  return axiosProspectInstance({
    url,
    method: 'GET',
    headers
  })
    .then(response => response.data)
    .catch(({ response }) => response.data);
}
