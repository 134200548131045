export const TEST_MODE = process.env.REACT_APP_CLICKWRAP_TEST_MODE === 'true';
export const ACCESS_ID = process.env.REACT_APP_CLICKWRAP_ACCESS_ID;
export const GROUP_PREFIX = process.env.REACT_APP_CLICKWRAP_GROUP_PREFIX;
export const TERMS_OF_USE = process.env.REACT_APP_CLICKWRAP_TERMS_OF_USE;

declare global {
  interface Window {
    _ps: any;
    amplitude: any;
  }
}

export const countryList = [
  'mx' // Mexico
] as const;

// Any other country will get the default contract
export const DEFAULT = 'default';

/* nosemgrep */
export type CustomerCountryType = (typeof countryList)[number];

const isValidCountry = (customerCountry: string): customerCountry is CustomerCountryType => {
  return countryList.includes(customerCountry?.toLowerCase() as CustomerCountryType);
};

export const getGroupKey = (countryCode: string) => {
  let groupKey = null;
  let country = countryCode;

  if (!isValidCountry(country)) country = DEFAULT;

  if (GROUP_PREFIX && TERMS_OF_USE && country) {
    groupKey = `${GROUP_PREFIX}-${country}-${TERMS_OF_USE}`.toLowerCase();
  }
  return groupKey;
};

export const initClickwrap = (accessId: string) => {
  if (window._ps !== undefined)
    window._ps('create', accessId, {
      disable_sending: true
    });
};

export const sendAgreement = async (groupKey: string, success?: () => void, error?: () => void) => {
  window._ps(groupKey + ':send', 'agreed', {
    disable_sending: false,
    event_callback: (err: any) => {
      if (err && error) return error();
      if (success) success();
    }
  });
};
