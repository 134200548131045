import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  IAccounCreation,
  IAmplitudeAccount,
  ILeads,
  InitialState,
  ISalesRange
} from '../../types/Interface';

const initialState: InitialState = {
  isLoading: false,
  error: false,
  accountCreationError: '',
  message: '',
  errorMessage: '',
  isCreatingAccount: false,
  isLeadsSuccess: false,
  salesRange: [] as ISalesRange[],
  leads: {} as ILeads,
  leadsError: '',
  groupKey: '',
  amplitudeAccount: {} as IAmplitudeAccount,
  registerForm: {} as IAccounCreation
};

const accountCreationSlice = createSlice({
  name: 'accountCreation',
  initialState,
  reducers: {
    // eslint-disable-next-line
    setSalesRange: (state, action: PayloadAction<{ countryCode: string }>) => {
      state.isLoading = true;
    },
    setSalesRangeSuccess: (state, action: PayloadAction<{ data: ISalesRange[] }>) => {
      const { data } = action.payload;
      state.isLoading = false;
      state.salesRange = data;
      state.error = false;
    },
    setRegister: (
      state,
      // eslint-disable-next-line
      action: PayloadAction<{
        newAccount: IAccounCreation;
        isAccountRejected: boolean;
        registerForm: IAccounCreation;
      }>
    ) => {
      const { registerForm } = action.payload;
      state.isLoading = true;
      state.error = false;
      state.isLeadsSuccess = false;
      state.accountCreationError = '';
      state.isCreatingAccount = true;
      state.registerForm = registerForm;
    },
    setRegisterSuccess: (state, action: PayloadAction<{ leads: ILeads }>) => {
      const { leads } = action.payload;
      state.isLoading = false;
      state.isLeadsSuccess = true;
      state.leads = leads;
      state.accountCreationError = '';
      state.isCreatingAccount = true;
    },
    setRegisterFailure: (state, action: PayloadAction<{ error: string }>) => {
      const { error } = action.payload;
      state.accountCreationError = error;
      state.isLoading = false;
      state.isLeadsSuccess = false;
      state.isCreatingAccount = false;
      state.leads = {} as ILeads;
    },
    setResetAccountCreation: state => {
      state.isCreatingAccount = false;
      state.leads = {} as ILeads;
    },
    setLeads: state => {
      state.isLoading = true;
      state.error = false;
    },
    setLeadsSuccess: (state, action: PayloadAction<{ leads: ILeads }>) => {
      const { leads } = action.payload;
      state.leads = leads;
      state.isLoading = false;
      state.error = false;
      state.isLeadsSuccess = true;
    },
    setLeadsFailure: (state, action: PayloadAction<{ error: string }>) => {
      const { error } = action.payload;
      state.isLoading = false;
      state.error = true;
      state.isLeadsSuccess = false;
      state.leadsError = error;
    },
    resetState: () => {
      return initialState;
    },
    setGroupKey: (state, action: PayloadAction<{ groupKey: string }>) => {
      const { groupKey } = action.payload;
      state.groupKey = groupKey;
    },
    setAmplitudeAccount: (
      state,
      action: PayloadAction<{ amplitudeAccount: IAmplitudeAccount }>
    ) => {
      const { amplitudeAccount } = action.payload;
      state.amplitudeAccount = amplitudeAccount;
    }
  }
});

export const { actions, reducer } = accountCreationSlice;
