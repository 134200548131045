import { actions as accountCreationActions } from './AccountCreation/AccountCreation.slice';
import { actions as userDataActions } from './UserData/UserData.slice';

const {
  setRegister,
  setSalesRange,
  resetState,
  setResetAccountCreation,
  setLeads,
  setGroupKey,
  setAmplitudeAccount,
  setRegisterFailure
} = accountCreationActions;
const {
  setProfileCreation,
  setProfileCreationSuccess,
  setProfileCreationFailure,
  setApplicationParticipants,
  setApplicationParticipantsSuccess,
  setApplicationParticipantsFailure,
  setSubmitApplication,
  setSubmitApplicationFailure,
  setSubmitApplicationSuccess
} = userDataActions;

export {
  setRegister,
  resetState,
  setSalesRange,
  setResetAccountCreation,
  setLeads,
  setGroupKey,
  setAmplitudeAccount,
  setRegisterFailure
};
export {
  setProfileCreation,
  setProfileCreationSuccess,
  setProfileCreationFailure,
  setApplicationParticipants,
  setApplicationParticipantsSuccess,
  setApplicationParticipantsFailure,
  setSubmitApplication,
  setSubmitApplicationFailure,
  setSubmitApplicationSuccess
};
