import { BUSINESS_HQ_CODE } from '../constants/businessHQ';

const createCurrencyRepresentationConfig = (
  thousandsSeparatorSymbol = ',',
  decimalSymbol = '.'
) => ({ thousandsSeparatorSymbol, decimalSymbol });

const getCurrencyRepresentationConfigByHQ = businessHQCode => {
  switch (businessHQCode) {
    case BUSINESS_HQ_CODE.BR:
      return createCurrencyRepresentationConfig('.', ',');
    default:
      return createCurrencyRepresentationConfig();
  }
};

const separateThousands = (value, thousandsSeparatorSymbol) =>
  value
    .split('')
    .reverse()
    .join('')
    .replaceAll(/\d{3}(?!$)/g, `$&${thousandsSeparatorSymbol}`)
    .split('')
    .reverse()
    .join('');

const formatMoneyAmount = (value, currencyRepresentationConfig) => {
  const { decimalSymbol, thousandsSeparatorSymbol } =
    currencyRepresentationConfig || createCurrencyRepresentationConfig();
  const [integerPart, decimalPart] = String(value).split('.');

  const preparedIntegerPart = separateThousands(integerPart, thousandsSeparatorSymbol);

  return decimalPart ? [preparedIntegerPart, decimalPart].join(decimalSymbol) : preparedIntegerPart;
};

export { formatMoneyAmount, getCurrencyRepresentationConfigByHQ };
