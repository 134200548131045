export const PROHIBITED_COUNTRIES = [
  'AF',
  'BI',
  'CF',
  'TD',
  'CD',
  'CU',
  'ER',
  'IR',
  'KP',
  'LY',
  'ML',
  'MM',
  'PK',
  'SO',
  'SS',
  'SD',
  'SY',
  'VE',
  'YE'
];
export const TOP_BUSINESS_HQ_COUNTRIES = ['MX', 'SA', 'AE'];
export const SOCIAL_NETWORKS = [
  'facebook.com',
  'medium.com',
  'pinterest.com',
  'snapchat.com',
  'linkedin.com',
  'reddit.com',
  'twitter.com',
  'tiktok.com',
  'tumblr.com',
  'instagram.com',
  'whatsapp.com',
  'youtube.com',
  'facebook.com',
  'flickr.com',
  'ask.fm',
  'vk.com',
  'ok.ru',
  'meetup.com',
  'tinder.com',
  'waze.com',
  'foursquare.com',
  'telegram.org',
  'slideshare.net'
];
