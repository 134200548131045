import { IAccounCreation } from '../types/Interface';
import { axiosInstance } from './prospects.instance';

export const createAccount = (data: IAccounCreation) => {
  const url = '/v1/leads';
  return axiosInstance({
    url,
    method: 'POST',
    data
  })
    .then(res => res.data)
    .catch(({ response }) => response.data);
};

export const getSalesRanges = (countryCode: string) => {
  const url = `/v1/sales_ranges?countryCode=${countryCode}`;
  return axiosInstance({
    url,
    method: 'GET'
  })
    .then(res => res.data)
    .catch(({ response }) => response.data);
};

export const getLeads = async () => {
  const url = '/v1/leads';
  return axiosInstance({
    url,
    method: 'get'
  })
    .then(res => res.data)
    .catch(({ response }) => response.data);
};
